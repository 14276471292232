import React, {useEffect, useState, Fragment, useContext, useRef} from 'react'
import {OverlayTrigger, Tooltip } from 'react-bootstrap'
import {Store} from '../../../storage/Store';
import axios from 'axios'
import { Socket } from '../../router/AppRouter';
import moment from 'moment';

import './chat.css';
import ChatUploader from './uploader';

function Chat(props) {

    const {state, dispatch} = useContext(Store);
    const me = state.userdata;

    const [usetTo, setUserTo] = useState(props.otherUser);
    const [id, setId] = useState(props.id);
    const [messageList, setMessageList] = useState(props.messages);
    const [messageListCount, setMessageListCount] = useState(props.messages.length);

    const [messageText, setMessageText] = useState('');
    const [files, setFiles] = useState([]);

    const [sending, setSending] = useState(false);
    const [loading, setLoading] = useState(true);

    console.log(messageList);

    const recieveMessages = () => {
        Socket.on('publipaid new chat message', (data) => {
            console.log('publipaid new chat message', data);
            if(String(data.ad) === String(id)){
                let actualMessages = messageList;
                actualMessages.unshift(data);
                setMessageList(actualMessages);
                setMessageListCount(messageListCount + 1);
                axios.get('/es/ecommerce/api/advertisements/'+id+'/chat-messages-viewed/');
            }
        });

        Socket.on('publipaid user joined', (data) => {
            if(String(data.shortUserId) === String(usetTo.id)){
                setUserTo({...usetTo, is_online: true});
                setMessageListCount(messageListCount + 1);
            }
        });

        Socket.on('publipaid user left', (data) => {
            if(String(data.shortUserId) === String(usetTo.id)){
                setUserTo({...usetTo, is_online: false});
                setMessageListCount(messageListCount + 1);
            }
        });
    }

    useEffect(() => {
        if(loading){
            setLoading(false);
            console.log(me);
            console.log(messageList);
            recieveMessages();
        }
    });

    const sendMessage = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setSending(true);

        let url = '/es/ecommerce/api/advertisements/'+id+'/send-chat-message/';

        let actualMessages = messageList;

        let fd = new FormData();
        const config = {headers: { 'Content-Type': 'multipart/form-data' }};

        fd.append('ad', id);
        fd.append('message', messageText);

        if(files.length > 0){
            fd.append('file', files[0]);
        }

        
        axios.post(url, fd, config).then((res) => {

            console.log(res.data);
            actualMessages.unshift(res.data);
            setMessageList(actualMessages);
            setMessageText('');
            setSending(false);

            let result = res.data;

            Socket.emit('publipaid new chat message', {
                from: result.user,
                to: result.to,
                message: result.message,
                date_created: result.date_created,
                file: result.file,
                ad: result.ad,
                token: "",
                dest_operation_type: "W"
            });

        }).catch((err) => {

            console.log('No se ha podido enviar el mensaje');
            console.error(err);
            setSending(false);

        });
    }

    return (
        <div className="w-100 bg-graypp p-3">
            <p>
                <i className="fa fa-comment mr-1"></i>Enviar mensaje a: <span className="font-weight-bold">{usetTo.full_name}</span> - {usetTo.id}
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip>
                            Miembro
                        </Tooltip>
                    }
                >
                    <i className="ml-1 rank-sm rank-bullet--3"></i>
                </OverlayTrigger>
                <OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip>
                            {usetTo.is_online ? 'En linea' : 'Desconectado'}
                        </Tooltip>
                    }
                >
                    <i className={((usetTo.is_online) ? 'online' : '')+" fa fa-male user user-mt-2 ml-1"}></i>
                </OverlayTrigger>
            </p>
            <form className="mt-2" onSubmit={(e) => sendMessage(e)}>
                <div className="fom-group mb-3">
                    <textarea readOnly={sending} required value={messageText} onChange={(e) => setMessageText(e.target.value)} name="" id="" placeholder="Escribe tu mensaje" rows="5" className="form-control"></textarea>
                </div>
                <div className="mb-2">
                    <button disabled={sending} type="submit" className="btn px-3 fs-p font-weight-bold btn-primary">
                        {(sending) ? <i className="fa fa-spin fa-spinner"></i> : 'Enviar'}
                    </button>
                    <ChatUploader files={files} setFiles={setFiles} />
                </div>
                {(files.length) === 0 &&
                    <p className="mb-0 h7">Adjuntar solo documento en formato PNG o JPG</p>
                }
                {(files.length) > 0 &&
                    <p className="mb-0 h7">Archivo: {files[0].name}</p>
                }
            </form>
            <div className="chat-content" id={'chat-'+id}>
                    {messageList && messageList.length > 0 &&
                        <ul className="chat-messages-list">
                            {messageList.map((message, index) => {
                                let meId = String(me.chat_credentials.uid);
                                let uId = String(message.user);

                                let idU = '';

                                if(meId === uId){
                                    idU = me.id;
                                }else{
                                    idU = usetTo.id;
                                }

                                //console.log(me.chat_credentials.uid);
                                //console.log(message.user);

                                return (
                                    <li className="chat-message" key={index}>
                                        <p className="mb-1">{moment(message.date_created).format('DD/MM/YYYY hh:mm:ss')}</p>
                                        <p className="mb-1"><span className="mr-2 font-weight-bold">{idU}:</span>{message.message}</p>
                                        {message.file && message.file !== '' &&
                                            <a className="d-block w-100 text-center text-primary" href={message.file} target="_blank">Ver archivo</a>
                                        }
                                    </li>
                                )
                            })}
                        </ul>
                    }
            </div>
        </div>    
    )
}

export default Chat;
