import React, {useState, useEffect, Fragment} from 'react';
import axios from 'axios';
import moment from 'moment';
import {withRouter, Link} from 'react-router-dom';
import {OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';
import TabsMobile from '../../../helpers/tabsMobile';
import LoaderInline from '../../../helpers/loader/LoaderInline';

import InfiniteScroll from 'react-infinite-scroll-component';

function MySales(props) {

    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState(true);
    const [list, setList] = useState([]);
    const [datalist, setDataList] = useState([]);

    const [balance, setBalance] = useState(true);

    const [paginateIn, setPaginateIn] = useState('');
    const [urlNext, setUrlNext] = useState('');

    const changeToOnline = (id) => {
        var newData = data;
        newData[id].seller.is_online = true;
        setData([...newData]);
    }

    useEffect(() => {
        if(loading){
            let url = '/es/ecommerce/api/my-sales/';
            let urlbalance = '/es/profile/api/my-sale-balance/';

            if(search){
                setSearch(false);
                console.log('load my sales');

                axios({
                    url,
                    method: 'get'
                }).then((res) => {
                    console.log(res);

                    setDataList(res.data);
                    setList(res.data.results);

                    setPaginateIn(res.data.results.length);
                    setUrlNext(res.data.next);

                    axios({
                        url: urlbalance,
                        method: 'get'
                    }).then((res) => {
                        console.log(res);
    
                        setBalance(res.data);
                        setLoading(false);

                    }).catch((err) => {
                        console.error(err);
                    });
                }).catch((err) => {
                    console.error(err);
                });
            }
        }
    });

    const fetchData = () => {
        console.log('buscando por la nueva pagina:'+ urlNext);
        let actualList = list;
        console.log(actualList);

        axios({
            method: 'get',
            url: urlNext
        }).then((res) => {
            console.log(res.data);
            for(var i=0; i<res.data.results.length; i++){
                console.log(res.data.results[i]);
                actualList.push(res.data.results[i]);
            }

            console.log(actualList);
            setList(actualList);
            setUrlNext(res.data.next);

        }).catch((err) => {
            console.error(err);
        });
    }

    let moreData = urlNext !== null && urlNext !== '';

    return (
        <div>
            <TabsMobile />
            {(props.location.state && props.location.state.message) &&
                <Alert variant='success'>
                    {props.location.state.message}
                </Alert>
            }
            {(props.location.state && props.location.state.messageInfo) &&
                <Alert variant='info'>
                    {props.location.state.messageInfo}
                </Alert>
            }
            {(!loading) &&
                <div>
                    <h3 className="bg-gray p-4 mb-2 h5 font-weight-bold">
                        <span className="text-success mr-2">Saldo de venta:</span> US$ {Number(balance.sale_balance).toFixed(2)}
                    </h3>
                    <table className="account mb-4">
                        <tbody>
                            <tr>
                                <td className="text-left">
                                    <a className="font-weight-bold" href="https://publipaid.com/es/rented-referrals/direct-rent/">Haga click aquí para alquilar referidos con saldo de venta</a>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-left">
                                    <a className="font-weight-bold" href="https://publipaid.com/es/account-transfer/">Haga click aquí para transferir tu saldo de venta a saldo disponible</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }

            <h2 className="h6 font-weight-bold mb-4">Mis ventas</h2>
            {(loading) && 
                <LoaderInline />
            }
            {(!loading) &&
                <div className="table-responsive table-center text-center">
                    <InfiniteScroll
                        dataLength={list.length}
                        hasMore={moreData}
                        next={fetchData}
                        loader={<div className="text-center mt-5 h3"><i className="fa fa-spin fa-spinner"></i></div>}
                        //endMessage={<p className="text-center">Sin mas registros</p>}
                    >
                    <table className="table-ads table-striped table-hover table">
                        <thead>
                            <tr>
                                <th>Operación#</th>
                                <th className="d-sm-datatable-none">Creado en</th>
                                <th className="d-sm-datatable-none">Comprador</th>
                                <th className="d-sm-datatable-none">Precio</th>
                                <th>Artículo</th>
                                <th>Estado</th>
                                <th className="d-sm-datatable-none">Calificación</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(list.length > 0) &&
                            <Fragment>
                                {list.map((item, index) => {
                                    let classIcon = '';

                                    if(item.rating_by_buyer){
                                        if(item.rating_by_buyer.rating === '0'){
                                            classIcon = 'normal';
                                        }else if(item.rating_by_buyer.rating === 'N'){
                                            classIcon = 'bad';
                                        }else if(item.rating_by_buyer.rating === 'P'){
                                            classIcon = 'good';
                                        }
                                    }

                                    return(
                                        <tr key={index}>
                                            <td>
                                                <div className="w-25-table-sm"></div>
                                                
                                                <Link to={(item.is_editable) ? '/my-advertisement/'+item.id : '/advertisement/'+item.id} className="text-uppercase btn btn-link px-0 text-primary font-weight-bold fs-p">{item.id} - ver</Link>
                                                {(Number(item.unread_chat_messages_count) > 0) &&
                                                    <p>
                                                        <span className="badge badge-danger mb-0 d-block">{item.unread_chat_messages_count}</span>
                                                    </p>
                                                }
                                                <span className="d-md-none d-block">{moment(item.date_created).format('DD/MM/YYYY')}</span>
                                                <div className="d-md-none d-block">
                                                    {(item.buyer) &&
                                                        <Fragment>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        {(item.buyer.is_online) ? 'En linea' : 'Offline'}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <i className={"fa fa-male user mr-1 "+ ((item.buyer.is_online) ? 'online' : '')}></i>
                                                            </OverlayTrigger>
                                                            {item.buyer.id}
                                                            {item.buyer.rank !== null &&
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        Miembro {item.buyer.rank_name}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <i className={"ml-1 rank-bullet--"+ item.buyer.rank}></i>
                                                            </OverlayTrigger>
                                                            }
                                                        </Fragment>
                                                    }
                                                </div>
                                            </td>
                                            <td className="d-sm-datatable-none">{moment(item.date_created).format('DD/MM/YYYY')}</td>
                                            <td className="d-sm-datatable-none">
                                                <div style={{minWidth: '85px'}}>
                                                    {(item.buyer) &&
                                                        <Fragment>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        {(item.buyer.is_online) ? 'En linea' : 'Offline'}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <i className={"fa fa-male user mr-1 "+ ((item.buyer.is_online) ? 'online' : '')}></i>
                                                            </OverlayTrigger>
                                                            {item.buyer.id}
                                                            {item.buyer.rank !== null &&
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        Miembro {item.buyer.rank_name}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <i className={"ml-1 rank-bullet--"+ item.buyer.rank}></i>
                                                            </OverlayTrigger>
                                                            }
                                                        </Fragment>
                                                    }
                                                </div>
                                            </td>
                                            <td className="d-sm-datatable-none text-success font-weight-bold"><div style={{minWidth: '75px'}}>USD$ {Number(item.amount).toFixed(2)}</div></td>
                                            <td>
                                                <div className="w-50-table-sm">
                                                    <div>{item.title}</div>
                                                    <div className="d-md-none d-block text-success font-weight-bold">USD$ {Number(item.amount).toFixed(2)}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="w-25-table-sm">
                                                    {item.status_name}
                                                    {(item.rating_by_buyer) &&
                                                        <span style={{height: '22px', float: 'inherit'}} className={"mt-2 d-md-none d-block qualification-rank rank-icon mx-auto " + classIcon}></span>
                                                    }
                                                </div>
                                            </td>             
                                            <td className="d-sm-datatable-none text-center">
                                                {(item.rating_by_buyer) &&
                                                    <span style={{height: '22px', float: 'inherit'}} className={"qualification-rank rank-icon mx-auto " + classIcon}></span>
                                                }
                                                </td>
                                        </tr>
                                    )
                                })}
                            </Fragment>
                            }

                            {(list.length === 0) &&
                                <tr>
                                    <td colSpan="7" className="text-center">
                                        <i className="fa fa-warning mr-3"></i>
                                        No ha realizado ninguna venta
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    </InfiniteScroll>
                </div>
            }
        </div>
    )
}

export default withRouter(MySales);
