//modules
import React, {Fragment} from 'react';
import Header from './Header';
import Footer from './Footer';

export default function PublicComponent(props){
    const showLayout = !props.onlyComponent;

    //console.log(props.onlyComponent);

    const renderComponent = ((showLayout)?
        <Fragment>
            <Header />
            <div id="wrapper">
                <div className="mt-3 pt-3">
                    <div className="container">
                        {props.children}
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    :
        props.children
    );

    return(  
        <Fragment>
            {renderComponent}
        </Fragment>
    );
}