import React, {useContext, useState, useEffect, Fragment} from 'react';
import {Link, withRouter, useLocation} from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';

import {Store} from '../../../../storage/Store';

import ImageProduct2 from '../../../../assets/market/home/mockup/2.png';

import SearchBar from '../../../helpers/searchbar';
import TabsMobile from '../../../helpers/tabsMobile';
import LoaderInline from '../../../helpers/loader/LoaderInline';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function SubCategory(props) {
    const {state} = useContext(Store);

    let categories = state.navbarCategories;
    let actualCategoryInRoute = props.match.params.category;
    let actualSubCategoryInRoute = props.match.params.subcategory;

    const [categoryInRoute, setCategoryInRoute] = useState(props.match.params.category);
    const [subCategoryInRoute, setSubCategoryInRoute] = useState(props.match.params.subcategory);

    const [thisCategory, setThisCategory] = useState([]);
    const [thisSubCategory, setThisSubCategory] = useState([]);

    const [id, setId] = useState('');
    const [loading, setLoading] = useState(true);

    let category = categories.filter(categories => categories.slug === categoryInRoute); 
    let subCategory = category[0].subcategories.filter(subcategory => subcategory.slug === subCategoryInRoute); 
   
    //console.log(actualCategoryInRoute);
    //console.log(category);

    const [loadingAds, setLoadingAds] = useState(true);
    const [emptyAds, setEmptyAds] = useState(true);
    const [searchAds, setSearchAds] = useState(true);

    const [adsList, setAdsList] = useState([]);
    const [urlNext, setUrlNext] = useState('');

    const [textSearch, setTextSearch] = useState('');

    let query = useQuery();

    const getAdvertisementsBySearch = (searchData) => {
        if(!loading){
            setLoading(true);
        }

        let url = '/es/ecommerce/api/advertisements/category/'+id+'/?search='+searchData;

        axios({
            method: 'get',
            url
        }).then((res) => {
            //console.log(res.data);
            setTextSearch(searchData);
            setLoadingAds(false);
            setLoading(false);
            setAdsList(res.data);
            setUrlNext(res.data.next);
            setEmptyAds(false);

        }).catch((err) => {
            console.error(err);
        });
    }

    useEffect(() => {
        if(loading){
            if(subCategory.length){
                if(id === ''){
                    setThisCategory(category[0]);
                    setThisSubCategory(subCategory[0]);
                    setId(subCategory[0].id);
                }else{
                    if(loadingAds){
                        console.log('Buscando anuncios por la categoria:'+id);
                        if(query.get('search') !== null && query.get('search') !== ''){
                            getAdvertisementsBySearch(query.get('search'));
                        }else{
                            let url = '/es/ecommerce/api/advertisements/category/'+id+'/';
                            axios.get(url)
                                .then((res) => {
                                    console.log(res);
                                    setLoadingAds(false);
                                    setLoading(false);
                                    setAdsList(res.data);
                                    setUrlNext(res.data.next);
                                    setEmptyAds(false);
                                }).catch((err) => {
                                    console.error(err);
                            });  
                        }
                    } 
                }
            }else{
                console.log('la sub categoria no ha podido ser encontrada');
                setLoading(false);
            }
        }else{
            if(subCategoryInRoute !== actualSubCategoryInRoute){
                setCategoryInRoute(actualCategoryInRoute);
                setSubCategoryInRoute(actualSubCategoryInRoute);
                setId('');
                setLoading(true);
                setLoadingAds(true);
                setAdsList([]);
                setSearchAds(false);
            }

            if(textSearch !== null && textSearch !== '' && props.location.search === ''){
                setTextSearch('');
                setLoading(true);
                setLoadingAds(true);
            }
            
        }

    });

    const handleSearch = (textInputSearch) => {
        //getAdvertisementsBySearch(textInputSearch);
        //console.log(props.location);

        if(textInputSearch !== '' && textInputSearch !== null){
            props.history.push(props.location.pathname+'?search='+textInputSearch);
        }else{
            props.history.push(props.location.pathname);
        }

        setLoading(true);
        setLoadingAds(true);
        setTextSearch(textInputSearch);
    }

    const fetchData = () => {
        console.log('buscando por la nueva pagina');
        let actualList = adsList.results;
        console.log(actualList);

        axios({
            method: 'get',
            url: urlNext
        }).then((res) => {
            console.log(res.data);

            for(var i=0; i<res.data.results.length; i++){
                console.log(res.data.results[i]);
                actualList.push(res.data.results[i]);
            }

            console.log(actualList);
            setAdsList({...adsList, results:actualList});
            setUrlNext(res.data.next);

        }).catch((err) => {
            console.error(err);
        });
    }

    let moreData = urlNext !== null && urlNext !== '';

    if(!loading){
        if(!emptyAds){
            return (
                <div id="content-category-list-products">
                    <TabsMobile />
                    {(!loadingAds) &&
                        <SearchBar defaultValue={textSearch} handleSearch={handleSearch} isDisabled={loadingAds} />
                    }
                    <h6 className="mb-0 mt-4 font-weight-bold category-title">{thisCategory.title} > {thisSubCategory.title} </h6>
                    {(!loadingAds) &&
                        <Fragment>
                            {(adsList.results && adsList.results.length > 0) &&
                            <InfiniteScroll
                                dataLength={adsList.results.length}
                                hasMore={moreData}
                                next={fetchData}
                                loader={<div className="text-center mt-3 h3"><i className="fa fa-spin fa-spinner"></i></div>}
                                //endMessage={<p className="text-center">Sin mas registros</p>}
                            >
                                <div className="row align-items-stretch mb-5 pb-5 category-product-list">
                                    <Fragment>
                                        {adsList.results.map((item,key) => {
                                        return <div key={key} className="col-sm-6 col-md-4 mt-4 col-6 product">
                                                    <Link to={'/advertisement/'+item.id} className="card h-100 w-100">
                                                        <div className="product-image" style={{backgroundImage: 'url('+((item.photos.length > 0) ? item.photos[0].file : ImageProduct2)+')'}}>
                                                        </div>
                                                        <div className="card-body">
                                                            <h3 className="product-title h7 text-primary">{item.title}</h3>
                                                            <h3 className="h6 text-success font-weight-bold product-price">US$ {Number(item.amount).toFixed(2)}</h3>
                                                            <p className="text-muted mb-1"><i className="mr-2 fa fa-clock-o"></i>
                                                                Publicado {moment(item.date_published).fromNow()}
                                                            </p>
                                                            <p className="text-muted mb-1"><i className="mr-2 fa fa-map-marker"></i>{item.location}.</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                        })}
                                    </Fragment>
                                </div>
                            </InfiniteScroll>
                            }

                            {(adsList.results && adsList.results.length === 0) &&
                                <div className="products-not-found text-center mt-4">
                                    <h3><i className="fa fa-warning"></i></h3>
                                    <h6 className="mt-3">No se encontraron anuncios en esta categoria</h6>
                                </div>
                            }
                        </Fragment>
                    }

                    {(loadingAds) &&
                        <LoaderInline />
                    }
                </div>
            )
        }else{
            return (
                <div className="products-not-found text-center mt-4">
                    <h2><i className="fa fa-warning"></i></h2>
                    <h4 className="mt-3">La categoria que buscas no ha podido ser encontrada<br/>compruebe la url o seleccione alguna de las categorias disponibles en el menú</h4>
                    <h6 className="mt-3 text-muted">Si cree que esto podría tratarse de un bug por favor contactar al equipo de soporte</h6>
                </div>
            );
        }
    }else{
        return (
            <Fragment>
                <TabsMobile />
                <SearchBar defaultValue={textSearch} handleSearch={handleSearch} isDisabled={loadingAds} />
                {(id !== '') &&
                    <h6 className="mb-0 mt-4 font-weight-bold category-title">{thisCategory.title} > {thisSubCategory.title} </h6>
                }
                <LoaderInline />
            </Fragment>
        )
    }
}

export default withRouter(SubCategory);
