import React, {useContext, Fragment, useState} from 'react';
import ReactPhoneInput from 'react-phone-input-2';

import {Store} from '../../../storage/Store';

import 'react-phone-input-2/dist/style.css';
import './style.css';

export default function PhoneInput(props){
    const {state} = useContext(Store);
    const [locale] = useState(require('./es.json'));
    const actualLanguage = state.lang;
    //console.log(actualLanguage);

    return(
        <Fragment>
            {(actualLanguage !== 'en') &&
                <div className="content-input-phone">
                    <ReactPhoneInput
                        {...props}
                        localization={locale}
                        enableSearchField={true}
                        disableSearchIcon={true}
                        disabled={props.disabled}
                    />
                </div>
            }
            {(actualLanguage === 'en') &&
                <div className="content-input-phone">
                    <ReactPhoneInput
                        {...props}
                        enableSearchField={true}
                        disableSearchIcon={true}
                        disabled={props.disabled}
                    />
                </div>
            }
        </Fragment>
    );
}