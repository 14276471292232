import React, {useContext, useState} from 'react'
import Select from 'react-select'

import {Store} from '../../../storage/Store';

function CategoriesSelect(props) {
    const {state} = useContext(Store);
    const [categories] = useState(state.navbarCategories);
    const [formatedCategories, setFormatedCategories] = useState([]);

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };

    const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };

    
    const formatGroupLabel = data => (
        <div style={groupStyles}>
            <span>{data.label}</span>
            <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    const formatToReactSelect = (categoriesList) => {
        let numberCategories = categoriesList.length;
        var categoriesFormat = [];

        for(var i = 0; i < numberCategories; i++){
            let thisCategory = categoriesList[i];
            let subCategories = thisCategory.subcategories;
            let numberSubCategories = subCategories.length;

            let thisCategoryFormated = {};
                thisCategoryFormated.label = thisCategory.title;
                //thisCategoryFormated.value = thisCategory.id;
                thisCategoryFormated.options = [];

                for(var j = 0; j < numberSubCategories; j++){
                    let thisSubCategory = subCategories[j];
                    let thisSubCategoryFormated = {};

                    thisSubCategoryFormated.label = thisSubCategory.title;
                    thisSubCategoryFormated.value = thisSubCategory.id;

                    thisCategoryFormated.options.push(thisSubCategoryFormated);

                    if(props.defaultValue){
                        if(thisSubCategory.id === props.defaultValue){
                           props.setDefaultValue(thisSubCategoryFormated);
                        };
                     }
                }

            //console.log(thisCategoryFormated);
            categoriesFormat.push(thisCategoryFormated);
        }

        //console.log('Categorias');
        //console.log(categoriesFormat);
        setFormatedCategories(categoriesFormat);
    };

    if(formatedCategories.length < 1){
        //console.log('Cargar categorias');
        formatToReactSelect(categories);
    }

    //console.log('category');
    //console.log(props.defaultValue);

    //RENDER
    if(formatedCategories.length < 1){
        return (
            <div className="form-group content-categories-select">
                <Select isDisabled={props.disabled} placeholder="Cargando data.." options={[]} />
            </div>
        )
    }else{
        return (
            <div className={(props.errorMessage) ? 'form-group content-categories-select react-select-error' :'form-group content-categories-select'}>
                <Select {...props} formatGroupLabel={formatGroupLabel} options={formatedCategories} />
                {(props.errorMessage) &&
                    <span className="text-danger h7">{props.errorMessage}</span>
                }
            </div>
        )
    }
}

export default CategoriesSelect;
