export const pageLoaded = async dispatch => {
    const setPageLoaded = await dispatch({
      type: 'PAGE_LOADED',
      payload: false
    });

    return setPageLoaded;
};

export const pageLoading = async dispatch => {
  const setPageLoading = await dispatch({
    type: 'PAGE_LOADING',
    payload: true
  });

  return setPageLoading;
};

export const setLanguage = async (dispatch, language) => {

  const changelanguage = await dispatch({
    type: 'SET_LANGUAGE',
    payload: language
  });

  return changelanguage;
};

export const loginUser = async (dispatch) => {
  localStorage.setItem('is_loged', true);
  const login = await dispatch({
    type: 'LOGIN',
    payload: true
  });
  return login;
};

export const handleLogout = async (dispatch) => {
  localStorage.setItem('is_loged', false);
  const logout = await dispatch({
    type: 'LOGOUT',
    payload: false
  });
  return logout;
};

export const setCategoriesList = async (dispatch, categoriesList) => {
  const setCategoriesList = await dispatch({
    type: 'SET_CATEGORIES',
    payload: categoriesList
  });
  return setCategoriesList;
};

export const setAcessToken = async (dispatch, token) => {
  const setToken = await dispatch({
    type: 'SET_ACCESS_TOKEN',
    payload: token
  });
  return setToken;
};

export const set_price_filters = async (dispatch, min, max) => {
  const setPriceFilters = await dispatch({
    type: 'SET_PRICE_FILTERS',
    payload: {min,max}
  });
  return setPriceFilters;
};

export const set_user_data = async (dispatch, data) => {
  const setUserData = await dispatch({
    type: 'SET_USER_DATA',
    payload: data
  });
  return setUserData;
};
