import React, {useContext, useState, useEffect, Fragment} from 'react';
import {Link, withRouter, useLocation} from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Store} from '../../../../storage/Store';

import ImageProduct2 from '../../../../assets/market/home/mockup/2.png';

import SearchBar from '../../../helpers/searchbar';
import TabsMobile from '../../../helpers/tabsMobile';
import LoaderInline from '../../../helpers/loader/LoaderInline';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Category(props) {
    const {state} = useContext(Store);

    let categories = state.navbarCategories;
    let actualCategoryInRoute = props.match.params.category;

    const [categoryInRoute, setCategoryInRoute] = useState(props.match.params.category);
    const [thisCategory, setThisCategory] = useState([]);
    const [id, setId] = useState('');
    const [loading, setLoading] = useState(true);

    let category = categories.filter(categories => categories.slug === categoryInRoute); 

    //console.log(actualCategoryInRoute);
    //console.log(category);
    const [locationState, setLocationState] = useState(props.location.state);

    const [loadingAds, setLoadingAds] = useState(true);
    const [emptyAds, setEmptyAds] = useState(true);
    const [searchAds, setSearchAds] = useState(true);
    const [adsList, setAdsList] = useState([]);

    const [urlNext, setUrlNext] = useState('');

    const [searchList, setSearchList] = useState(true);

    const [textSearch, setTextSearch] = useState('');
    const [min, setMin] = useState((props.location.state && props.location.state.min) ? props.location.state.min : '');
    const [max, setMax] = useState((props.location.state && props.location.state.max) ? props.location.state.max : '');

    let query = useQuery();

    //console.log(props.location);
    //console.log(min);
    //console.log(max);

    const getAdvertisementsBySearch = (searchData) => {
        let url = '/es/ecommerce/api/advertisements/category/'+id+'/?search='+searchData;
        axios({
            method: 'get',
            url
        }).then((res) => {
            //console.log(res.data);
            setTextSearch(searchData);
            setLoadingAds(false);
            setLoading(false);

            setAdsList(res.data);
            setUrlNext(res.data.next);

            setEmptyAds(false);
        }).catch((err) => {
            console.error(err);
        });
    }

    const setFilters = () => {
        if(min !== '' && max !== '' && (Number(min) < Number(max)) && (query.get('search') !== null && query.get('search') !== '')){
            let url = '/es/ecommerce/api/advertisements/category/'+id+'/'+(Number(min))+'/'+(Number(max))+'/?search='+(query.get('search'));
            
            setTextSearch(query.get('search'));

            axios({
                url,
                method: 'get'
            }).then((res) => {
                    console.log(res);
                    setLoadingAds(false);
                    setLoading(false);

                    setAdsList(res.data);
                    setUrlNext(res.data.next);
                    
                    setEmptyAds(false);
                }).catch((err) => {
                    console.error(err);
            });
        }else if(query.get('search') !== null && query.get('search') !== ''){

            getAdvertisementsBySearch(query.get('search'));

        }else if(min !== '' && max !== '' && (Number(min) < Number(max))){

            let url = '/es/ecommerce/api/advertisements/category/'+id+'/'+(Number(min))+'/'+(Number(max))+'/';
            console.log('filtrar por precio');
            axios({
                url,
                method: 'get'
            }).then((res) => {
                    console.log(res);
                    setLoadingAds(false);
                    setLoading(false);

                    setAdsList(res.data);
                    setUrlNext(res.data.next);

                    setEmptyAds(false);
                }).catch((err) => {
                    console.error(err);
            });

        }
    }

    useEffect(() => {
        if(loading){
            if(category.length){
                if(id === ''){
                    setThisCategory(category[0]);
                    setId(category[0].id);
                }else{
                    if(loadingAds){
                        if(min !== '' && max !== '' && (Number(min) < Number(max)) || (query.get('search') !== null && query.get('search') !== '')){
                            setFilters();
                        }else{
                            if(searchList){
                                setSearchList(false);
                                //console.log('Buscando anuncios por la categoria:'+id);
                                let url = '/es/ecommerce/api/advertisements/category/'+id+'/';
                                axios.get(url)
                                    .then((res) => {
                                        setAdsList(res.data);
                                        setUrlNext(res.data.next);

                                        setEmptyAds(false);
                                        console.log(res);
                                        setLoadingAds(false);
                                        setLoading(false);

                                    }).catch((err) => {
                                        console.error(err);
                                });  
                            }
                        }
                    } 
                }
            }else{
                console.log('la categoria no ha podido ser encontrada');
                setLoading(false);
            }
        }else{
            if(categoryInRoute !== actualCategoryInRoute){
                setCategoryInRoute(actualCategoryInRoute);
                setId('');
                setLoading(true);
                setLoadingAds(true);
                setAdsList([]);
                setSearchAds(false);
            }

            if(textSearch !== null && textSearch !== '' && props.location.search === ''){
                setTextSearch('');
                setLoading(true);
                setLoadingAds(true);
            }

            if(props.location.state !== locationState){
                if(props.location.state.min !== min || props.location.state.max !== max){
                    setMin(props.location.state.min);
                    setMax(props.location.state.max);

                    setLoading(true);
                    setLoadingAds(true);

                    setAdsList([]);
                    setSearchAds(false);
                }
            }
        }
    });

    const handleSearch = (textInputSearch) => {
        //getAdvertisementsBySearch(textInputSearch);
        //console.log(props.location);

        const location = {
            pathname: props.location.pathname,
            state: props.location.state,
            search: ((textInputSearch !== null && textInputSearch !== '') ? '?search='+textInputSearch : '')
        }

        props.history.push(location);
        
        setLoading(true);
        setLoadingAds(true);
        setTextSearch(textInputSearch);
    }

    const fetchData = () => {
        console.log('buscando por la nueva pagina');
        let actualList = adsList.results;
        console.log(actualList);

        axios({
            method: 'get',
            url: urlNext
        }).then((res) => {
            console.log(res.data);

            for(var i=0; i<res.data.results.length; i++){
                console.log(res.data.results[i]);
                actualList.push(res.data.results[i]);
            }

            console.log(actualList);
            setAdsList({...adsList, results:actualList});
            setUrlNext(res.data.next);

        }).catch((err) => {
            console.error(err);
        });
    }

    let moreData = urlNext !== null && urlNext !== '';
    console.log(urlNext);
    console.log(moreData);

    if(!loading){
        if(!emptyAds){
            return (
                <div id="content-category-list-products">
                    <TabsMobile />
                    <SearchBar defaultValue={textSearch} handleSearch={handleSearch} isDisabled={loadingAds} />

                    <h6 className="mb-0 mt-4 font-weight-bold category-title">{thisCategory.title}</h6>
                    {(!loadingAds) &&
                        <Fragment>
                            {(adsList.results && adsList.results.length > 0) &&
                                <InfiniteScroll
                                    dataLength={adsList.results.length}
                                    hasMore={moreData}
                                    next={fetchData}
                                    loader={<div className="text-center mt-3 h3"><i className="fa fa-spin fa-spinner"></i></div>}
                                    //endMessage={<p className="text-center">Sin mas registros</p>}
                                >
                                    <div className="row align-items-stretch mb-5 pb-5 category-product-list">
                                        <Fragment>
                                            {adsList.results.map((item,key) => {
                                            return <div key={key} className="col-sm-6 col-md-4 mt-4 col-6 product">
                                                        <Link to={'/advertisement/'+item.id} className="card h-100 w-100">
                                                            <div className="product-image" style={{backgroundImage: 'url('+((item.photos.length > 0) ? item.photos[0].file : ImageProduct2)+')'}}>
                                                            </div>
                                                            <div className="card-body">
                                                                <h3 className="product-title h7 text-primary">{item.title}</h3>
                                                                <h3 className="h6 text-success font-weight-bold product-price">US$ {Number(item.amount).toFixed(2)}</h3>
                                                                <p className="text-muted mb-1"><i className="mr-2 fa fa-clock-o"></i>
                                                                    Publicado {moment(item.date_published).fromNow()}
                                                                </p>
                                                                <p className="text-muted mb-1"><i className="mr-2 fa fa-map-marker"></i>{item.location}.</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                            })}
                                        </Fragment>
                                    </div>
                                </InfiniteScroll>

                            }

                            {(adsList.results && adsList.results.length === 0) &&
                                <div className="products-not-found text-center mt-4">
                                    <h3><i className="fa fa-warning"></i></h3>
                                    <h6 className="mt-3">No se encontraron anuncios en esta categoria</h6>
                                </div>
                            }
                        </Fragment>
                    }

                    {(loadingAds) &&
                        <LoaderInline />
                    }
                </div>
            )
        }else{
            return (
                <div className="products-not-found text-center mt-4">
                    <h2><i className="fa fa-warning"></i></h2>
                    <h4 className="mt-3">La categoria que buscas no ha podido ser encontrada<br/>compruebe la url o seleccione alguna de las categorias disponibles en el menú</h4>
                    <h6 className="mt-3 text-muted">Si cree que esto podría tratarse de un bug por favor contactar al equipo de soporte</h6>
                </div>
            );
        }
    }else{
        return (
            <Fragment>
                <TabsMobile />
                <SearchBar defaultValue={textSearch} handleSearch={handleSearch} isDisabled={loadingAds} />
                {(id !== '') &&
                    <h6 className="mb-0 mt-4 font-weight-bold category-title">{thisCategory.title}</h6>
                }
                <LoaderInline />
            </Fragment>
        )
    }
}

export default withRouter(Category);
