//modules
import React, {Fragment} from 'react';
import {Row,Col} from 'react-bootstrap';

import '../../assets/backoffice/css/backoffice.css';

//components

import NavbarBackoffice from './NavbarBackoffice';

import Header from './Header';
import Footer from './Footer';

export default function PrivateComponent(props){
    const showLayout = !props.onlyComponent;
    const showPriceFilters = props.showPriceFilters;

    const renderComponent = ((showLayout)?
        <Fragment>
            <Header />
            <div id="wrapper">
                <div className="container">
                    <Row>
                        <Col xs="12" lg="3" className="d-lg-block d-none">
                            <NavbarBackoffice match={props.match} showPriceFilters={showPriceFilters} panelReferrals={true} />
                        </Col>
                        <Col xs="12" lg="9">
                            <div className="d-none d-lg-block mt-5"></div>
                            <div className="pt-4">
                                {props.children}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <Footer />
        </Fragment>
    :
        props.children
    );
    
    return(  
        <Fragment>
            {renderComponent}
        </Fragment>
    );
}