import React, {useState, useEffect} from 'react'

function SearchBar(props) {

    const [textInputSearch, setTextInputSearch] = useState((props.defaultValue) ? props.defaultValue : '');

    const handleChangeInputSearch = (e) => {
        if (e.which === 13) {
            e.preventDefault();
            if(props.handleSearch){
                props.handleSearch(textInputSearch);
            }
        }
    }

    const search = () => {
        if(props.handleSearch){
            props.handleSearch(textInputSearch);
        }
    }

    return (
        <div id="search">
            <form action="">
                <div className="form-group form-group-has-icon">
                    <input 
                        type="text" 
                        value={textInputSearch}
                        disabled={props.isDisabled} 
                        placeholder={(props.placeholderText) ? props.placeholderText  :"Buscar en mercado"} 
                        className="form-control"
                        onKeyPress={(e) => handleChangeInputSearch(e)}
                        onChange={(e) => setTextInputSearch(e.target.value)}
                    />
                    <button disabled={props.isDisabled}  type="button" onClick={() => search()} className="input-group-icon">
                        <span id="search-icon"><i className="fa fa-search"></i></span>
                    </button>
                </div>
            </form>
        </div>
    )

}

export default SearchBar;
