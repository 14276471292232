import React, {useEffect, useState, Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import axios from 'axios';
import BuyerPurchase from './purchase_buyer';
import SellerPurchase from './purchase_seller';

function Purchase(props) {

    const [typeUser, setTypeUser] = useState(props.data.current_user_info);

    if(typeUser.is_actor){
        return (
            <div>
                {(typeUser.is_buyer) &&
                    <Fragment>
                        <BuyerPurchase {...props} />
                    </Fragment>
                }
                {(typeUser.is_seller) &&
                    <Fragment>
                        <SellerPurchase {...props} />
                    </Fragment>
                }
            </div>
        )
    }else{
        return (
            <div>
                No esta autorizado para ver informacion en esta página.
            </div>
        )
    }
}

export default withRouter(Purchase);
