import _transfer from './en/transfer.json';

import _password_change from './en/password_change.json';
import _tabs_account from './en/tabs_account.json';
import _account_close from './en/account_close.json';
import _my_testimony from './en/my_testimony.json';
import _user_verification from './en/user_verification.json';
import _update_account from './en/update_account.json';
import _header from './en/header.json';
import _register from './en/register.json';
import _login from './en/login.json';
import _sidebar from './en/sidebar.json';
import _reset_password from './en/reset_password.json';
import _reset_password_done from './en/reset_password_done.json';
import _menu from './en/menu.json';
import _not_found from './en/not_found.json';
import _set_password from './en/set_password.json';
import _confirmation_email_sent from './en/confirmation_email_sent.json';
import _reset_done from './en/reset_done.json';
import _set_password_fail from './en/set_password_fail.json';
import _faq from './en/faq.json';
import _file_input from './en/file_input.json';
import _ws_groups from './en/ws_groups.json';
import _rented_referrals from './en/rented_referrals.json';
import _manage_operations from './en/manage_operations.json';
import _tab_market from './en/tab_market.json';
import _manage_operation_sales from './en/manage_operation_sales.json';
import _manage_operation_deposit from './en/manage_operation_deposit.json';
import _withdraw from './en/withdraw.json';
import _deposit from './en/deposit.json';
import _direct_referrals from './en/direct_referrals.json';
import _rented_referrals_referrals from './en/rented_referrals_referrals.json';
import _anti_spam_policy from './en/anti_spam_policy.json';
import _privacy_policy from './en/privacy_policy.json';
import _policy_of_refund from './en/policy_of_refund.json';
import _terms from './en/terms.json';
import _advertisements from './en/advertisements.json';
import _show_ads from './en/show_ads.json';
import _movements from './en/movements.json';
import _ranks from './en/profile/ranks.json';

//market
import _market_ad from './en/market/market_ad.json';

//modals
import _modal_warning from './en/modals/modal_warning.json';

export default {
    locale: "en",
    header: _header,
    register: _register,
    login: _login,
    reset_password: _reset_password,
    reset_password_done: _reset_password_done,
    set_password: _set_password,
    set_password_fail: _set_password_fail,
    reset_done: _reset_done,
    not_found: _not_found,
    confirmation_email_sent: _confirmation_email_sent,
    menu: _menu,
    sidebar: _sidebar,
    transfer: _transfer,
    password_change: _password_change,
    tabs_account: _tabs_account,
    account_close: _account_close,
    my_testimony: _my_testimony,
    user_verification: _user_verification,
    update_account: _update_account,
    faq: _faq,
    fileInput: _file_input,
    ws_groups: _ws_groups,
    rented_referrals: _rented_referrals,
    manage_operations:_manage_operations,
    tab_market: _tab_market,
    manage_operation_sales: _manage_operation_sales,
    manage_operation_deposit: _manage_operation_deposit,
    withdraw: _withdraw,
    deposit: _deposit,
    direct_referrals: _direct_referrals,
    rented_referrals_referrals: _rented_referrals_referrals,
    anti_spam_policy: _anti_spam_policy,
    privacy_policy: _privacy_policy,
    policy_of_refund: _policy_of_refund,
    terms: _terms,
    advertisements: _advertisements,
    show_ads: _show_ads,
    movements: _movements,
    market_ad: _market_ad,
    modal_warning: _modal_warning,
    ranks: _ranks
}
