import React, {Fragment, useState, useEffect} from 'react'
import axios from 'axios';
import LoaderInline from '../loader/LoaderInline';

function RatingChoices(props) {

    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState(false);
    const [data, setData] = useState([]);

    const [sending, setSending] = useState(false);

    const [showMessage, setShowMessage] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState('');

    const [dataRating, setDataRating] = useState((props.defaultValue !== '') ? props.defaultValue.rating : '');
    const [comment, setComment] = useState((props.defaultValue !== '') ? props.defaultValue.comments : '');

    const [dataDefaultValue, setDataDefaultValue] = useState(props.defaultValue);

    useEffect(() => {
        //console.log(dataDefaultValue);

        if(loading){
            if(!search){
                setSearch(true);
                let url = '/es/ecommerce/api/master/rating-choices/';
                axios({
                    url,
                    method: 'get'
                }).then((res) => {
                    console.log(res.data);
                    setData(res.data);
                    setLoading(false);
                }).catch((err) => {
                    console.error(err);
                });
            }
        }
    });

    const handleRadioOnChange = (e, value) => {
        setDataRating(value);
    }

    const sendRating = () => {
        console.log(props.id);
        console.log(comment);
        console.log(dataRating);

        setSending(true);
        setMessageSuccess('');
        setShowMessage(false);

        if(dataDefaultValue === '' || dataDefaultValue.length === 0){
            let url = '/es/ecommerce/api/ratings/advertisement/'+props.id+'/';
            axios({
                method: 'post',
                url,
                data: {
                    rating: dataRating,
                    comments: comment,
                }
            }).then((res) => {
                console.log(res.data);
                setSending(false);
                setMessageSuccess('Su calificación ha sido enviada');
                setShowMessage(true);
                setDataDefaultValue(res.data);
            }).catch((err) => {
                console.error(err);
                setMessageSuccess('');
                setShowMessage(false);
            });
        }else{
            let url = '/es/ecommerce/api/ratings/advertisement/'+props.id+'/';
            axios({
                method: 'post',
                url,
                data: {
                    rating: dataRating,
                    comments: comment,
                }
            }).then((res) => {
                console.log(res.data);
                setSending(false);
                setMessageSuccess('Su calificación ha sido editada correctamente');
                setShowMessage(true);
                setDataDefaultValue(res.data);
            }).catch((err) => {
                console.error(err);
                setMessageSuccess('');
                setShowMessage(false);
            });
        }
    }

    return (
        <div>
            {(!loading && data.length > 0) &&
                <Fragment>
                    <ul className="rank-list mx-auto text-center">
                        {(data.map((rating, index) => {
                        let classIcon = '';

                        if(rating.value === '0'){
                            classIcon = 'normal';
                        }else if(rating.value === 'N'){
                            classIcon = 'bad';
                        }else if(rating.value === 'P'){
                            classIcon = 'good';
                        }

                        return(  <li key={index}>
                                    <label className="content-pp-radio" htmlFor={rating.value}>
                                        <div className={classIcon+" rank-icon"}></div>
                                        <span className="rank-count">{rating.label}</span>
                                        <div className="pp-radio">
                                            <input defaultChecked={(rating.value === dataRating)} onChange={(e) => handleRadioOnChange(e, rating.value)} id={rating.value} name="qualification" type="radio"/>
                                        </div>
                                    </label>
                                </li>
                            )
                        }))}
                    </ul>
                    <div className="form-group">
                        <textarea value={comment} onChange={(e) => setComment(e.target.value)} name="" id="" rows="5" placeholder="Escribe un comentario" className="form-control"></textarea>
                    </div>
                    <button
                    disabled={(sending || (dataRating === '' || comment === ''))}
                    onClick={() => sendRating()} className="btn d-block w-sm-100 mx-auto btn-success btn-sm fs-p font-weight-bold mt-3 mb-4 py-2 px-4">
                        {(dataDefaultValue === '' || dataDefaultValue.length === 0) &&
                            <Fragment>
                                {(sending) ? <Fragment><i className="fa fa-spin fa-spinner mr-2"></i> Enviando</Fragment> : 'Publicar comentario'}
                            </Fragment>
                        }

                        {(dataDefaultValue !== '' || dataDefaultValue.length > 0) &&
                            <Fragment>
                                {(sending) ? <Fragment><i className="fa fa-spin fa-spinner mr-2"></i> Enviando</Fragment> : 'Editar comentario'}
                            </Fragment>
                        }
                    </button>
                    {(showMessage) &&
                        <p className="text-success">{messageSuccess}</p>
                    }
                </Fragment>
            }

            {(loading) &&
                <LoaderInline />
            }
        </div>
    )
}

export default RatingChoices;
