//modules
import React, {Fragment, useContext, useState} from 'react';
import {Link, Route, withRouter} from 'react-router-dom';
import {Store} from '../../storage/Store';

import iconMarket from '../../assets/market/market-icon.png';

//translate
//import {useTranslate} from 'react-translate';

function SidebarLink({ actualPath = '', subMenu = false, subMenuItems = [], matchUrl, activeWithPathnames = [], label, to, icon, activeOnlyWhenExact }) {

    //console.log(actualPath);
    //console.log(matchUrl);
    //console.log(activeWithPathnames.includes(actualPath));
    
    return (
      <Route
        path={to}
        exact={activeOnlyWhenExact}
        children={({ match}) => (
            <Fragment>
                {(!subMenu)?
                    <li className={(match || (!activeOnlyWhenExact && activeWithPathnames.includes(actualPath))) ? "active" : ""}>
                        <Link to={{ pathname: to, state: {category_name: true} }} className="page-scroll">
                            {icon}
                            {label}
                        </Link>
                    </li> 
                :
                    <li className={(match || (!activeOnlyWhenExact && activeWithPathnames.includes(actualPath))) ? "active pp-item-menu-content" : "pp-item-menu-content"}>
                        <Link to={to} className="page-scroll pp-item-menu">
                            {icon}
                            {label}
                        </Link>
                        {(match || (matchUrl.category && to.includes(matchUrl.category))) &&
                            <ul className="pp-submenu">
                                {subMenuItems.map((subitem, index) => {
                                    return <li key={index} className={(matchUrl.subcategory && subitem.slug.includes(matchUrl.subcategory)) ? "active" : ""}>
                                                <Link to={{ pathname: to+'/'+subitem.slug }} className="page-scroll">
                                                    <i className="fa fa-arrow-right mr-2"></i>
                                                    {subitem.title}
                                                </Link>
                                            </li>
                                })}
                            </ul>
                        }
                    </li>
                }
            </Fragment>
        )}
      />
    );
}

function NavbarBackoffice(props) {
    //const [showPanel] = useState(props.panelReferrals);
    //console.log(props.location);

    //let t = useTranslate("sidebar");
    const {state, dispatch} = useContext(Store);

    const [min, setMin] = useState((props.location.state && props.location.state.min) ? props.location.state.min : '');
    const [max, setMax] = useState((props.location.state && props.location.state.max) ? props.location.state.max : '');

    let categories = state.navbarCategories;

    function validate(evt) {
        var theEvent = evt || window.event;
        
        // Handle paste
        if (theEvent.type === 'paste') {
            key = evt.clipboardData.getData('text/plain');
        } else {
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }

        var regex = /[0-9]|\./;
        if( !regex.test(key) ) {
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    const filterAdsByPrice = (e) => {
        e.preventDefault();
        e.stopPropagation();

        var minFilter = String(min);
        var maxFilter = String(max);

        if(maxFilter > minFilter){
            //alert('min:'+min+' max:'+max);
            //console.log(props.location);
            const location = {
                pathname: props.location.pathname,
                state: { min:minFilter, max:maxFilter },
                search: ((props.location.search !== null && props.location.search !== '') ? props.location.search : '')
            }

            props.history.push(location);
        }
    }

    return (
        <React.Fragment>
            <div className="navbar-backoffice navbar-backoffice-market">
                <div className="menu-backoffice">
                    <ul className="nav pt-4 navbar-nav navbar-general">
                        <li className="separator d-flex align-items-center market-icon-separator">
                            <img src={iconMarket} className="mr-1" alt=""/><span style={{fontSize: '1.2em'}} className="text-dark pt-1 font-weight-bold">Market</span>
                        </li>
                        <SidebarLink matchUrl={props.match.params} actualPath={props.location.pathname} activeOnlyWhenExact={true} to="/" icon={<i className="fa fa-search"></i>} label="Explorar" />
                        <SidebarLink matchUrl={props.match.params} actualPath={props.location.pathname} activeOnlyWhenExact={true} to="/sell-now" icon={<span className="icon icon-sell-now"></span>} label="Vender Ahora" />
                        <SidebarLink matchUrl={props.match.params} actualPath={props.location.pathname} activeOnlyWhenExact={true} to="/my-shopping" icon={<span className="icon icon-my-shopping"></span>} label="Mis compras" />
                        <SidebarLink matchUrl={props.match.params} actualPath={props.location.pathname} activeOnlyWhenExact={true} to="/my-sales" icon={<span className="icon icon-my-sales"></span>} label="Mis ventas" />
                    </ul>
                </div>
                {(props.showPriceFilters === true) &&
                    <div className="menu-backoffice">
                    <ul className="nav navbar-nav navbar-price navbar-referrels">
                        <li className="separator market">
                            Precio
                        </li>
                        <li>
                            <div className="container price-navbar">
                                <form onSubmit={(e) => filterAdsByPrice(e)}>
                                    <div className="form-row align-items-center">
                                        <div className="form-group m-0 col-5">
                                            <input name="min" value={min} onChange={(e) => setMin(e.target.value)} onKeyPress={(e) => validate(e)} className="form-control px-3 form-control-sm" placeholder="Desde" type="text"/>
                                        </div>
                                        <div className="form-group m-0 col-5">
                                            <input name="max" value={max} onChange={(e) => setMax(e.target.value)} onKeyPress={(e) => validate(e)} className="form-control px-3 form-control-sm" placeholder="Hasta" type="text"/>
                                        </div>
                                        <div className="form-group m-0 col-2">
                                            <button type="submit" disabled={(Number(max) < Number(min))} className="btn-rounded-icon">
                                                <i className="fa fa-caret-right"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </li>
                    </ul>
                </div>
                }
                <div className="menu-backoffice">
                    <ul className="nav navbar-nav navbar-referrels">
                        <li className="separator market">
                            Categorias
                        </li>
                        {categories && categories.length && categories.map((item, key) => {
                            var linkCategory = <SidebarLink 
                                                    key={key}
                                                    to={'/'+item.slug} 
                                                    icon={<span className={item.icon_css_class}></span>} 
                                                    label={item.title} 
                                                    activeOnlyWhenExact={true} 
                                                    matchUrl={props.match.params} 
                                                    actualPath={props.location.pathname} 
                                                    subMenu={true} 
                                                    subMenuItems={item.subcategories}
                                                />

                            return linkCategory;
                        })}
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
}

export default withRouter(NavbarBackoffice);