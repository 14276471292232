import React, {Fragment} from 'react';
import {FileManager, FileUploader} from 'reactjs-file-uploader';
import {Row, Col, Spinner, Button} from 'react-bootstrap';
import imageCompression from 'browser-image-compression';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import './ppfileuploader.css';
import IconFile from './file-icon.png';

export default class PpFileUploader extends React.Component {
 
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            events: {},
            progress: {},
            selectedIndex: null,
            autoUpload: false,
            showEvents: false,
            loadingImage: false,
            firstUpload: false,

            cropImage: false,
            dataCrop: '',
            imageCropped: false,
            urlPreview: ''
        };

        this.uploadFile = this.uploadFile.bind(this);
        this.removeThis = this.removeThis.bind(this);
        this.ButtonRemove = this.ButtonRemove.bind(this);
        this.handleImageUpload = this.handleImageUpload.bind(this);
        this.triggerUpload = this.triggerUpload.bind(this);
        this.editThis = this.editThis.bind(this);
        this._crop = this._crop.bind(this);
        this._cropImage = this._cropImage.bind(this);
    }

    triggerUpload(e){
        e.preventDefault();
        this.fileInput.click();
    }

    async handleImageUpload(e) {
        e.preventDefault();

        const component = this;
        //const files = e.target.files;
        const filesInUploader = this.state.files;
        const isMultiple = (this.props.multiple) ? this.props.multiple : false;
        //const idInput = (this.props.id) ? this.props.id : 'image';
        console.log('imagenes');
        console.log(e.target.files);

        let filesInInputUploader = e.target.files;
        var fileInput = filesInInputUploader[0];

        var numberOfInputFiles = filesInUploader.length;
        var numberOfUploadedFiles = filesInUploader.length;
        numberOfUploadedFiles =+ filesInInputUploader.length;

        var extension = '';

        async function compress(image){
            component.setState({
                loadingImage: true
            }, () => {
            var checkImage = new Image();
            checkImage.src = window.URL.createObjectURL( image );
                checkImage.onload = function() {
                    var width = 0;
                    var height = 0;
                    
                    width = (checkImage.naturalWidth * 40) / 100;
                    height = (checkImage.naturalHeight * 40) / 100;
                    window.URL.revokeObjectURL( checkImage.src );

                    const fileName = image.name;

                    const reader = new FileReader();
                    reader.readAsDataURL(image);
                    reader.onload = function(event) {
                            var extension = image.name.substring(image.name.lastIndexOf('.') + 1).toLowerCase();
                            const img = new Image();
                            img.src = event.target.result;
                            img.onload = () => {
                                const elem = document.createElement('canvas');
                                elem.id = 'renderOptimizedImage';
                                elem.width = width;
                                elem.height = height;
                                const ctx = elem.getContext('2d');
                                ctx.drawImage(img, 0, 0, width, height);
                                ctx.canvas.toBlob(async (blob) => {
                                    const setMaxSize = (image.size /1024 /1024) *75 /100;
                                    //console.log(setMaxSize);
                                    //console.log(`originalFile size ${image.size / 1024 / 1024} MB`);
                                    //console.log()
                                    var options = {
                                        maxSizeMB: setMaxSize,
                                        maxWidthOrHeight: 1024,
                                        useWebWorker: true
                                    }
                                    try {
                                        const compressedFile = await imageCompression(blob, options);
                                        //console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); 
                                        
                                            const file = new File([compressedFile], fileName.replace('.'+extension, '.jpg'), {
                                                type: 'image/jpg',
                                                lastModified: Date.now()
                                            });   

                                        //console.log(file);
                                        //console.log(fileInput);

                                        const numberImages = component.state.files.length;
                                        //console.log(`optimized file size ${file.size / 1024 / 1024} MB`);
                                        component.setState({files: component.state.files.concat(file)}, () => {
                                            
                                            //console.log('subiendo:'+numberOfUploadedFiles);
                                            //console.log(numberOfInputFiles);
                                            //console.log('imagenes subidas:'+numberImages);
                                            //console.log('imagenes en el state despues de subir:'+component.state.files.length);
                                            
                                            component.setState({
                                                loadingImage: !((numberOfUploadedFiles + numberOfInputFiles) === component.state.files.length)
                                            });
                                            
                                        });
                                            
                                    } catch (error) {
                                        //console.log(error);
                                    } 
                                }, 'image/jpg', .80);
                            }
                        }
                };
            });
        }

        async function addImage(image){
            await compress(image);
        }

        if(filesInInputUploader.length > 1){
            console.log('varias imagenes seleccionadas');
            for(var i = 0; i < filesInInputUploader.length; i++){
                var fileInput = filesInInputUploader[i];
                if(fileInput !== undefined){
                    extension = fileInput.name.substring(fileInput.name.lastIndexOf('.') + 1).toLowerCase();

                    if(extension === 'png' || extension === 'jpg' || extension === 'jpeg'){
                        console.log('añadiendo imagen');
                        addImage(fileInput);
                    }else{
                        console.log('añadiendo imagen al state');
                        component.setState({files: component.state.files.concat(fileInput)});
                    } 
                }
            }
        }else{
            if(fileInput !== undefined){
                extension = fileInput.name.substring(fileInput.name.lastIndexOf('.') + 1).toLowerCase();

                if(filesInUploader.length > 0 && !isMultiple){
                    if(extension === 'png' || extension === 'jpg' || extension === 'jpeg'){
                        this.setState({files: []}, () => {
                            addImage(fileInput);
                        }); 
                    }else{
                        this.setState({files: []}, () => {
                            component.setState({files: component.state.files.concat(fileInput)});
                        }); 
                    }
                }else{
                    if(extension === 'png' || extension === 'jpg' || extension === 'jpeg'){
                        addImage(fileInput);
                    }else{
                        component.setState({files: component.state.files.concat(fileInput)});
                    }
                }
            }
        }
    }
 
    render() {
        let buttonText = 'Sube fotos para tu anuncio';

        if(this.state.files.length > 0){
            buttonText = this.state.files.length+' Foto(s) seleccionadas';
        }

        return (
            <div>
                <input 
                    {...this.props}
                    type="file"  
                    className="d-none"
                    ref={fileInput =>  this.fileInput = fileInput}
                    onChange={(e) => this.handleImageUpload(e)}
                />
                <Row>
                    <Col xs={12} sm={7} md={6} lg={7}>
                        <button 
                            disabled={this.props.disabled}
                            onClick={this.triggerUpload}
                            className={((this.props.errorMessage && this.props.errorMessage !== '') ? 'border-danger' : '')+" btn d-block fileuploader-input-box w-100 text-left"}>
                            {buttonText}
                        </button>
                    </Col>
                    <Col xs={12} sm={5} md={6} lg={5}>
                        <button 
                        disabled={this.props.disabled}
                        onClick={this.triggerUpload}
                        className={((this.props.errorMessage && this.props.errorMessage !== '') ? 'border-danger' : '')+" btn d-block font-weight-bold fileuploader-input-button w-100 text-center"}>Seleccionar una foto</button>
                    </Col>
                </Row>
                {(this.state.files.length > 0) &&
                    <React.Fragment>
                        <div className="pp-filemanager mt-2">
                            <FileManager files={this.state.files}>
                                {files => <div>{files.map(this.uploadFile)}</div>}
                            </FileManager>
                        </div>
                        <button className="d-none font-weight-bold px-3 mt-2 btn btn-sm btn-success">Enviar</button>  
                    </React.Fragment>
                }
                {(this.state.loadingImage) && 
                    <h4 className="text-center h6 font-weight-bold pt-3">
                    <Spinner size="sm" animation="grow" variant="success" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner><span className="ml-3">Optimizando imagenes</span></h4>
                }
                {(this.state.cropImage) &&
                    <Fragment>
                        <Row>
                            <Col xs="12" sm="8" md="8">
                                <Cropper
                                    ref='cropper'
                                    src={this.state.dataCrop.fileData}
                                    style={{height: 250, width: '100%'}}
                                    // Cropper.js options
                                    aspectRatio={16 / 9}
                                    guides={false}
                                    crop={this._crop.bind(this)}
                                />
                                <Button variant="success" onClick={(e) => this._cropImage(e)} className="d-block w-100 mt-2">Cortar</Button>
                            </Col>
                            <Col xs="12" sm="4" md="4"> 
                                <h3 className="h5 font-weight-bold">Preview</h3>
                                {(this.state.imageCropped) &&
                                    <img src={this.state.urlPreview} className="img-fluid" alt="preview"/>
                                }
                            </Col>
                        </Row>
                    </Fragment>
                }
            </div>
        )
    }

    _cropImage(e){
        console.log("Cortando imagen");
        e.preventDefault();
        function dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','),
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:'image/jpg'});
        }
        const newImageCrop = dataURLtoFile(this.state.urlPreview, this.state.dataCrop.file.name);
        let actualListOfFiles = this.state.files;
        const oldImageIndex = actualListOfFiles.indexOf(this.state.dataCrop.file);

        if(oldImageIndex !== -1){
            actualListOfFiles[oldImageIndex] = newImageCrop;
            this.setState({
                files: actualListOfFiles
            }, () => {
                console.log(this.state.files);
                this.setState({
                    cropImage: false,
                    dataCrop: '',
                    imageCropped: false,
                    urlPreview: ''
                });
            });
        }
    }

    _crop(){
        const dataUrl = this.refs.cropper.getCroppedCanvas().toDataURL();
        this.setState({
            imageCropped: true,
            urlPreview: dataUrl
        });
    }

 
    uploadFile(file) {
        const component = this;
        return (
            <FileUploader
                key={file.key}
                file={file}
                url=''
                formData={{
                    file,
                    upload_preset: 'public',
                    tags: 'vanilla',
                }}
                readFile
            >

                {data => {
                //console.log(data);
                    return (
                        <div onClick={() => { this.setState({selectedIndex: file.key}) }} >
                            <div className="item-uploaded">
                                <Row>
                                    <Col xs={4} md={2}>
                                        {PpFileUploader.renderImage(data)}
                                    </Col>
                                    <Col xs={5} md={6} className="item-uploaded-description">
                                        {PpFileUploader.renderDataImage(data)}
                                    </Col>
                            </Row>
                            {component.ButtonRemove(data)}
                            </div>
                        </div>
                    );
                }}
            </FileUploader>
        )
    }

    removeThis(e, dataImage){
        e.preventDefault();
        const newFilesArray = this.state.files.filter(file => file !== dataImage);
        this.setState({
            files: newFilesArray
        });
    }

    editThis(e, dataImage){
        e.preventDefault();
        console.log(dataImage);

        this.setState({
            cropImage: true,
            dataCrop: dataImage
        });
    }

    ButtonRemove(data){
        const dataRemove = data.file;

        switch (data.requestState) {
            case FileUploader.UPLOAD_READY:
                    var extension = data.file.type;
                    var btnRender = (extension === 'image/jpg'
                    ?
                        <Fragment>
                            <button className="btn-remove-uploader" title="Delete image" onClick={(e) => this.removeThis(e, dataRemove)}>
                                <i className="fa fa-times"></i>
                            </button>
                            {/*
                            <button className="btn-remove-uploader bg-primary" title="Edit image" onClick={(e) => this.editThis(e, data)}>
                                <i className="fa fa-pencil"></i>
                            </button>*/}
                        </Fragment>
                    :
                        <button className="btn-remove-uploader" title="Delete image" onClick={(e) => this.removeThis(e, dataRemove)}>
                            <i className="fa fa-times"></i>
                        </button>
                    );
                return (
                        <React.Fragment>
                            {btnRender}
                        </React.Fragment>
                    );
            default:
                return <span></span>;
        }
    }

    static renderDataImage(data){
        function toMb(number){
            var size = Number(number / 1024).toFixed(2);
            var prefix = (size < 1000) ? 'kb' : 'mb';
            return size+' '+prefix;
        }
        switch (data.requestState) {
            case FileUploader.UPLOAD_READY:
                return (
                        <React.Fragment>
                            <h3 className="h6 font-weight-bold" title={data.file.name}>{data.file.name}</h3>
                            <h4 className="h6 text-muted small">{toMb(data.file.size)}</h4>
                        </React.Fragment>
                    );
            default:
                return <p>No se ha podido mostrar los datos</p>;
        }
    }

    static renderImage(data) {

        switch (data.requestState) {

            case FileUploader.UPLOAD_READY:
            var extension = data.file.type;
            var imgRender = ((extension === 'image/png' || extension === 'image/jpeg' || extension === 'image/jpg') ?
                <img alt={data.file.name} className="img-thumbnail-uploader" src={data.fileData || ''} />
            :
                <img alt={data.file.name} className="img-thumbnail-uploader" src={IconFile} />
            );

            return imgRender;

            case FileUploader.UPLOAD_START:
            case FileUploader.UPLOAD_PROGRESS:
            case FileUploader.UPLOAD_COMPLETE:
            case FileUploader.ABORT: {

                const progress = data.uploadProgress
                    ? Math.floor(data.uploadProgress.loaded / data.uploadProgress.total * 100)
                    : 0;

                return (
                    <div>cargando: {progress}%</div>
                );
            }

            case FileUploader.DOWNLOAD_PROGRESS:
            case FileUploader.DOWNLOAD_COMPLETE:
                return (
                    <div></div>
                );
            default:
                //Parece que ha ocurrido un error
                return <p>Something has gone wrong!</p>;
        }
    }
}