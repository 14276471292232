import React, {useState, useEffect, useRef} from 'react'

function ChatUploader(props) {

    const [file, setFile] = useState([]);

    //fileUploader
    const input = useRef(null);
    const formUpload = useRef(null);

    const setFileToChat = (e) => {
        var thisFile = e.target.files[0];

        if(thisFile.type !== 'image/png' && thisFile.type !== 'image/jpg' && thisFile.type !== 'application/pdf') {
            props.setFiles([]);
            formUpload.current.reset();
            alert('El archivo debe ser formato png o pdf');
        }else{
            let newFile = [];
            newFile.push(thisFile);
            
            props.setFiles(newFile);
        }
    }

    return (
        <div className="d-inline-block">
            <form ref={formUpload} action="">
                <input 
                    type="file"  
                    className="d-none"
                    ref={input}
                    onChange={(e) => setFileToChat(e)}
                    accept="image/x-png,image/jpg,application/pdf"
                />
            </form>
            <button onClick={() => input.current.click()} type="button" className="btn px-3 fs-p font-weight-bold bg-white ml-2">Adjuntar</button>
        </div>
    )
}

export default ChatUploader;
