import React, {useState, useRef, useEffect, Fragment} from 'react'
import {Row, Col} from 'react-bootstrap'
import {withRouter, Redirect} from 'react-router-dom';
import PhoneInput from '../../../helpers/phoneInput';
import PpFileUploader from '../../../helpers/fileinput';
import axios from 'axios';
import CategoriesSelect from '../../../helpers/categoriesSelect';
import LoaderInline from '../../../helpers/loader/LoaderInline';

import Select from 'react-select'
import TabsMobile from '../../../helpers/tabsMobile';

function SellNow(props) {
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState(true);

    //edit
    const isEdit = (props.edit) ? props.edit : false;
    const [editData, setEditData] = useState([]);
    const [loadDataEdit, setLoadDataEdit] = useState(false);
    const [deletingPhoto, setDeletingPhoto] = useState(false);
    const [photosDeleted, setPhotosDeleted] = useState([]);
    const [id, setId] = useState((props.edit) ? props.match.params.id : '');
    const [deletingPhotoId, setDeletingPhotoId] = useState('');
    const [redirectTo, setRedirectTo] = useState({});

    //create
    const [title, setTitle] = useState({text: '', maxLength: 100, minLength: 10});
    const [description, setDescription] = useState({text: '',maxLength: 550, minLength: 10});
    const [category, setCategory] = useState(null);
    const [amount, setAmount] = useState();
    const [phone, setPhone] = useState('');
    const [acceptReturns, setAcceptReturns] = useState(true);
    const [time_to_send, set_time_to_send] = useState({});

    //static files
    const [userData, setUserData] = useState([]);
    const [timesToSend, setTimesToSend] = useState([]);

    //fileUploader
    const files = useRef(null);

    const [sending, setSending] = useState(false);
    const [sended, setSended] = useState(false);

    const [showErrors, setShowErrors] = useState(false);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if(search){
            setSearch(false);
            axios({
                method:'get',
                url: '/es/profile/api/me/'
            }).then((res) => {
                let userdata = res.data;
                let phone = res.data.phone;
                //servicio para select de duracion de envio
                    axios({
                        method:'get',
                        url: '/es/ecommerce/api/master/times-to-send/'
                    }).then((res) => {
                        console.log(res.data);
                        setTimesToSend(res.data);
                        set_time_to_send(res.data[3]);
                        setUserData(userdata);
                        setPhone(phone);
                        
                        if(!isEdit){
                            setLoading(false);
                        }else{
                            let id = props.match.params.id;

                            axios({
                                method:'get',
                                url: '/es/ecommerce/api/advertisements/'+id+'/'
                            }).then((res) => {
                                console.log('datos del anuncio');
                                console.log(res.data);

                                //set data Edit
                                setEditData(res.data);
                                setTitle({...title, text: res.data.title});
                                setDescription({...description, text: res.data.description});
                                setAmount(res.data.amount);
                                setPhone(res.data.phone);
                                setAcceptReturns(res.data.accept_returns);

                                setLoadDataEdit(true);
                            }).catch((err) => {
                                console.error(err);
                            });
                        }
                    }).catch((err) => {
                        console.error(err);
                    });
            }).catch((err) => {
                console.error(err);
            });
        }

        if(isEdit && loadDataEdit && loading){
            let time = timesToSend.filter(option => option.value === editData.time_to_send)[0];
            set_time_to_send(time);
            setLoading(false);
        }
    });

    const createAd = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        let imagesList = files.current.state.files;
        let numberImages = imagesList.length;
        console.log('NUMERO DE IMAGENES:'+numberImages);
        console.log(time_to_send);
        console.log(time_to_send[0]);

        let dataAd = {
            title: title.text,
            description: description.text,
            category: ((category && category.value) ? category.value : ''),
            amount: Number(amount).toFixed(2),
            phone: phone,
            time_to_send: ((time_to_send && time_to_send.value) ? time_to_send.value : ''),
            accept_returns: acceptReturns
        }

        if( title.text === '' || description.text === '' || category === null || phone === ''){
            console.error('No se han podido enviar los datos');
        }else if(title.text.length < title.minLength || title.text.length > title.maxLength){
            let errorsTitle = {
                title: 'Debe ingresar un titulo para su anuncio de minimo '+title.minLength+' caracteres ó '+title.maxLength+' como maximo'
            }
            setErrors(errorsTitle);
            setShowErrors(true);

        }else if(description.text.length < description.minLength || description.text.length > description.maxLength){
            let errorsDesc = {
                description: 'Debe ingresar una descripción para su anuncio de minimo '+description.minLength+' caracteres ó '+description.maxLength+' como maximo'
            }
            setErrors(errorsDesc);
            setShowErrors(true);

        }else{
            setSending(true);
            setShowErrors(false);

            function toSetToPending(url){
                axios({
                    url:url,
                    method: 'put',
                }).then((res) => {
                    //console.log(res.data);
                    console.log('Anuncio publicado, estado:Pendiente');
                    if(!isEdit){
                        setRedirectTo({
                            pathname: "/my-sales/",
                            state: { message: "Tu anuncio fue creado y será publicado tan pronto lo revisemos."}
                        });
                        setSended(true);
                        setSending(false);
                    }else{
                        setRedirectTo({
                            pathname: "/my-advertisement/"+id+'/',
                            state: { message: "Tu anuncio fue editado correctamente."}
                        });
                        setSended(true);
                        setSending(false);
                    }
                }).catch((err) => {
                    console.log('Ha ocurrido un error al cambiar el estado del anuncio a: PENDIENTE');
                    console.log(err);
                });
            }

            if(!isEdit){
                let urlCreate = '/es/ecommerce/api/advertisements/create/';
                if(numberImages > 0 && numberImages <= 7){
                    await axios({
                        url:urlCreate,
                        method: 'post',
                        data: dataAd
                    }).then((res) => {
                        let idPost = res.data.id;
                        let urlAddPhotos = '/es/ecommerce/api/advertisements/'+idPost+'/photos/create/';
                        let numberImagesUploaded = 0;

                        if(numberImages > 0){
                            console.log('subir imagenes');
                            for(var i = 0; i < numberImages; i++){
                                let image = imagesList[i];

                                var fd = new FormData();
                                fd.append('file', image, image.name);            
                                const config = {headers: { 'Content-Type': 'multipart/form-data' }};
                                axios.post(urlAddPhotos,fd, config).then((res) => {
                                    console.log(res.data);
                                    numberImagesUploaded++;
                                    console.log(numberImagesUploaded);

                                    if(numberImagesUploaded === numberImages){

                                        let urlSetToPending = '/es/ecommerce/api/advertisements/'+idPost+'/set-to-pending/';
                                        toSetToPending(urlSetToPending);
                                        
                                    }
                                }).catch((err) => {
                                    console.log('Ha ocurrido un error al subir la imagen del anuncio');
                                    console.log(err);
                                });

                            }
                        }
                    }).catch((err) => {
                        if(err.response.status === 400){
                            console.log('La creacion del anuncio ha fallado');
                            console.log(err.response.data);
                            setErrors(err.response.data);
                            setShowErrors(true);
                            setSending(false);
                        }
                    });
                }else{
                    let photosError = {
                        photos: 'Debe subir una imagen como minimo ó 7 como maximo para poder crear su anuncio, actualmente ha cargado '+numberImages+' imagenes.'
                    }

                    setErrors(photosError);
                    setShowErrors(true);
                    setSending(false);
                }
            }else{
                let id = props.match.params.id;
                let url = '/es/ecommerce/api/advertisements/'+id+'/edit/';
                let totalPhotos = (editData.photos.length + numberImages) - photosDeleted.length;

                alert(totalPhotos);
                if(totalPhotos > 0 && totalPhotos <= 7){
                
                    await axios({
                        url,
                        method: 'put',
                        data: dataAd
                    }).then((res) => {
                        console.log('editado');
                        console.log(res.data);
                        setSending(false);

                        let idPost = res.data.id;
                        let urlAddPhotos = '/es/ecommerce/api/advertisements/'+idPost+'/photos/create/';
                        let numberImagesUploaded = 0;

                        if(numberImages > 0){
                            console.log('subir imagenes');
                            for(var i = 0; i < numberImages; i++){
                                let image = imagesList[i];

                                var fd = new FormData();
                                fd.append('file', image, image.name);            
                                const config = {headers: { 'Content-Type': 'multipart/form-data' }};
                                axios.post(urlAddPhotos,fd, config).then((res) => {
                                    console.log(res.data);
                                    numberImagesUploaded++;
                                    console.log(numberImagesUploaded);

                                    if(numberImagesUploaded === numberImages){
                                        let urlSetToPending = '/es/ecommerce/api/advertisements/'+idPost+'/set-to-pending/';
                                        toSetToPending(urlSetToPending);  
                                    }

                                }).catch((err) => {
                                    console.log('Ha ocurrido un error al subir la imagen del anuncio');
                                    console.log(err);
                                });
                            }
                        }else{
                            let urlSetToPending = '/es/ecommerce/api/advertisements/'+idPost+'/set-to-pending/';
                            toSetToPending(urlSetToPending);  
                        }

                    }).catch((err) => {
                        if(err.response.status === 400){
                            console.log('La edicion del anuncio ha fallado');
                            console.log(err.response.data);

                            setErrors(err.response.data);
                            setShowErrors(true);
                            setSending(false);
                        }
                    })

                }else{
                    let photosError = {
                        photos: 'Debe subir una imagen como minimo ó 7 como maximo para poder crear su anuncio, actualmente ha cargado '+totalPhotos+' imagenes.'
                    }

                    setErrors(photosError);
                    setShowErrors(true);
                    setSending(false);
                }

            }

        }
    }

    const deletePhoto = (id) => {
        //alert(id);
        setDeletingPhoto(true);
        setDeletingPhotoId(id);

        let url = '/es/ecommerce/api/advertisements/photos/'+id+'/delete/';

        //console.log('fotos eliminadasss');
        //console.log(photosDeleted);

        axios({
            url,
            method: 'delete'
        }).then((res) => {
            let newPhotosDeleteArray = photosDeleted;
            newPhotosDeleteArray.push(id);
            setPhotosDeleted(newPhotosDeleteArray);
            setDeletingPhotoId('');
            setDeletingPhoto(false);
        }).catch((err) => {
            console.error(err);
        })
    }

    const changeAmount = (e) => {
        let {value} = e.target;
        setAmount(value);
    }

    const handlePhoneOnChange = (value) => {
        setPhone(value);
    }

    const handleChangeCategory = async (selectedOption) => {
        setCategory(selectedOption); 
    };

    const handleChangeTimeToSend = async (selectedOption) => {
        set_time_to_send(selectedOption); 
    };

    function validate(evt) {
        var theEvent = evt || window.event;
        
        // Handle paste
        if (theEvent.type === 'paste') {
            key = evt.clipboardData.getData('text/plain');
        } else {
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }

        var regex = /[0-9]|\./;
        if( !regex.test(key) ) {
            theEvent.returnValue = false;
            if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    //console.log('fotos eliminadasss');
    //console.log(photosDeleted);

    if(!sended){
        return (
            <div id="sell-now">
                <TabsMobile />
                <form action="" encType="multipart/form-data" onSubmit={(e) => createAd(e)}>
                    <h4 className="mb-4">
                        {!(isEdit) ? 'Crear anuncio para vender en Publipaid' : 'Editar mi anuncio'}
                    </h4>
                    {(!loading) &&
                    <div className="row">
                        <div className="col-md-8">
                            <div className={(showErrors && errors.title) ? 'form-group has-error mb-2 form-group-has-icon' : 'form-group mb-2 form-group-has-icon'}>
                                <input required readOnly={sending} type="text" value={title.text} minLength={title.minLength} maxLength={title.maxLength} onChange={(e) => setTitle({...title, text: e.target.value})} placeholder="Crear un título descriptivo para tu anuncio" 
                                className={(showErrors && errors.title) ? 'form-control is-invalid' : 'form-control'}/>
                                <div className="input-group-icon text-muted">
                                    {Number(title.maxLength) - Number(title.text.length)}
                                </div>
                            </div>
                            {(showErrors && errors.title) &&
                                <span className="text-danger h7">{errors.title}</span>
                            }
                            <p className="help-block text-lightgray h7 mb-3">
                                Ejemplo: Zapatillas deportivas para mujer Adidas, negro, talla 39
                            </p>
                            <div className="form-group mb-3 form-group-has-icon">
                                <textarea required readOnly={sending} name="description" minLength={description.minLength} value={description.text} onChange={(e) => setDescription({...description, text: e.target.value})} placeholder="Describe el artículo" maxLength={description.maxLength} cols="30" rows="5" 
                                className={(showErrors && errors.description) ? 'form-control is-invalid' : 'form-control'}></textarea>
                                <div className="input-group-icon-top text-muted">
                                    {Number(description.maxLength) - Number(description.text.length)}
                                </div>
                            </div>
                            {(showErrors && errors.description) &&
                                <p className="text-danger h7">{errors.description}</p>
                            }
                            <div className="row mb-2 align-items-center form-group">
                                <div className="col-1">
                                    US$
                                </div>
                                <div className="col-4">
                                    <input required type="text" 
                                        readOnly={sending}
                                        value={amount} 
                                        onChange={(e) => changeAmount(e)} 
                                        placeholder="Precio" 
                                        onKeyPress={(e) => validate(e)}
                                        className={(showErrors && errors.amount) ? 'form-control is-invalid' : 'form-control'}
                                        //pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$"
                                    />
                                </div>
                                {(showErrors && errors.amount) &&
                                <p className="text-danger h7">{errors.amount}</p>
                                }
                            </div>
                            <p className="h7 mb-3"><i className="fa fa-map-marker mr-2 text-lightgray"></i>{userData.location}</p>
                            <CategoriesSelect 
                                isDisabled={sending}
                                isSearchable={true}
                                defaultValue={editData.category}
                                setDefaultValue={setCategory}
                                placeholder="Selecciona una categoria"
                                value={category}
                                onChange={handleChangeCategory}
                                errorMessage = {(showErrors && errors.category) ? errors.category : ''}
                            />
                            <div className="form-group mt-4">
                                {(showErrors && errors.photos) &&
                                    <p className="text-danger h7">{errors.photos}</p>
                                }
                                <PpFileUploader 
                                    multiple={true} 
                                    ref={files}
                                    disabled={sending}
                                    errorMessage = {(showErrors && errors.photos) ? errors.photos : ''}
                                />
                                {(isEdit && editData.photos && editData.photos.length > 0 && editData.photos.length !== photosDeleted.length) &&
                                    <Fragment>
                                        <hr/>
                                        <label htmlFor="">Imagenes cargadas</label>
                                        <Row>
                                        {editData.photos.map((photo, key) => {
                                             return <Fragment>
                                                        {((photosDeleted.find(photoid => photoid === photo.id) === undefined)) &&
                                                            <Col key={key} xs={4} md={3}>
                                                                <div className="img-edit-preview" style={{backgroundImage: 'url('+photo.file+')'}}>
                                                                    <button disabled={deletingPhoto || sending} onClick={() => deletePhoto(photo.id)} className="btn btn-danger btn-delete-photo-edit">
                                                                        {(deletingPhoto && deletingPhotoId === photo.id) ? <i className="fa fa-spin fa-spinner"></i> : <i className="fa fa-times"></i>}
                                                                    </button>
                                                                </div>
                                                            </Col>
                                                        }
                                                    </Fragment>      
                                                    
                                        })}
                                        </Row>
                                    </Fragment>
                                }
                            </div>
                            <div className="form-group mb-3">
                                <label htmlFor="">Whatsaap / Celular</label>
                                <PhoneInput 
                                    defaultCountry="pe"
                                    inputClass="form-control"
                                    value={phone} 
                                    onChange={handlePhoneOnChange}
                                    disabled={sending}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="">Tiempo para enviar el artículo</label>
                                <div className="content-categories-select">
                                    <Select 
                                        isDisabled={sending} 
                                        isSearchable={true}
                                        placeholder="Seleccione el tiempo.." 

                                        value={time_to_send} 
                                        
                                        onChange={handleChangeTimeToSend} 
                                        options={timesToSend} 
                                    />
                                </div>
                            </div>
                            <div className="devoluciones mb-3">
                                <p className="mb-0">¿Aceptas devoluciones?</p>
                                <div className="md-checkbox md-checkbox-inline">
                                    <input checked={acceptReturns} type="checkbox" id="yes" /> 
                                    <label onClick={() => setAcceptReturns(true)} htmlFor="yes">
                                        Si
                                    </label>
                                </div>
                                <div className="md-checkbox ml-4 md-checkbox-inline">
                                    <input checked={!acceptReturns} type="checkbox" id="no" /> 
                                    <label onClick={() => setAcceptReturns(false)} htmlFor="no">
                                        No
                                    </label>
                                </div>
                            </div>
                            {(!isEdit) &&
                                <button 
                                    disabled={((title.text === '' || description.text === '' || category === null || phone === '') || sending)}
                                    type="submit"
                                    style={{maxWidth: 320}} 
                                    className="w-100 my-0 py-2 d-block mx-0 btn btn-lg px-5 font-weight-bold btn-login btn-success mb-3">
                                        {(sending) ? <div>Cargando anuncio <i className="fa fa-spinner ml-1 fa-spin"></i></div> : 'Publicar anuncio'}
                                </button>
                            }
                            {(isEdit) &&
                                <button 
                                    disabled={((title.text === '' || description.text === '' || category === null || phone === '') || sending)}
                                    type="submit"
                                    style={{maxWidth: 320}} 
                                    className="w-100 my-0 py-2 d-block mx-0 btn btn-lg px-5 font-weight-bold btn-warning mb-3">
                                        {(sending) ? <div>Subiendo cambios <i className="fa fa-spinner ml-1 fa-spin"></i></div> : 'Editar anuncio'}
                                </button>
                            }
                            <p className="h7 text-lightgray">
                                Al publicar un anuncio, estas de acuerdo y aceptas los <a href="##" className="text-primary text-underline">Términos y Condiciones</a> de Publipaid.
                            </p>
                        </div>
                    </div>
                    }

                    {(loading) &&
                        <LoaderInline />
                    }
                </form>
            </div>
        )
    }else{
        return (
            <Redirect
                to={redirectTo}
            />
        );
    }
}

export default withRouter(SellNow);
