//modules
import React from 'react';
import ReactDOM from 'react-dom';

//translate
import Translator from './languages/Translator';

//router
import Router from './components/router'; 
import {AppRouter} from './components/router/AppRouter';

//style
import './styles/index.css';
import './styles/app.scss';
import './styles/page/frontend.css';
import './styles/page.css';
import './styles/market.css';

//Store
import {StoreProvider} from './storage/Store';

//tree
const app = <StoreProvider>
                <Translator>
                    <Router>
                        <AppRouter/>
                    </Router>
                </ Translator>
            </StoreProvider>;

ReactDOM.render(app, document.getElementById('root'));
