//modules
import React, {useContext, useState, useEffect, Fragment} from 'react';
import {Switch, Route, Redirect, useLocation} from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';

//storage
import {Store} from '../../storage/Store';
import {setCategoriesList, setAcessToken, set_user_data} from '../../storage/Actions';

//socket.io
import io from 'socket.io-client';

//Root component
import App from '../App';

//components
import Home from '../page/private/home';

//Public components container
import PublicComponent from '../core/PublicComponent';

//Private components container
import PrivateComponent from '../core/PrivateComponent';
import Category from '../page/private/category';
import SellNow from '../page/private/sell_now';
import SubCategory from '../page/private/category/SubCategory';
import SeeProduct from '../page/private/product';
import MySales from '../page/private/my-sales';
import MyPurchases from '../page/private/my-purchases';
import SeeAdSeller from '../page/private/my-advertisement';

import Login from '../page/login';
import Logout from '../page/logout';

import Loader from '../helpers/loader';
import Purchase from '../page/private/purchase';
import EditAdvertisement from '../page/private/edit';

require('moment/locale/es.js');
moment.locale('es');

//nota
//En este componente se definiran todas las rutas de la aplicacion
//todas las rutas son relativas y estan ligadas directamente con el basename configurado en en archivo ./index
var Socket;

function AppRouter(){
    const {state, dispatch} = useContext(Store);

    //Evaluar rutas privadas
    const PrivateRoute = ({ component: Component, Needlogin, onlyComponent = false, showPriceFilters = false, ...rest }) => {
        if(Needlogin){
            //Necesita estar logeado si no lo esta redirecciona al login
            return (
                <Route
                    {...rest}
                    render={
                        props => state.is_loged
                        ? 
                        (
                            <Fragment>
                                {(state.navbarCategories.length > 0 && state.userdata !== null)
                                    ?
                                        <PrivateComponent {...props} showPriceFilters={showPriceFilters} onlyComponent={onlyComponent}>
                                            <Component {...props} />
                                        </PrivateComponent>
                                    :
                                        <Loader />
                                }
                            </Fragment>
                        ) 
                        : 
                        (
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: { from: props.location }
                                }}
                            />
                        )
                    }
                />
            );
        }else{
            //Rutas solo accesibles cuando el usuario no esta logeado en este caso se redirecciona al inicio de marketplace
            return (
                <Route
                    {...rest}
                    render={
                        props => !state.is_loged
                        ? 
                        (   
                            <PublicComponent onlyComponent={onlyComponent}>
                                <Component {...props} />
                            </PublicComponent>
                        ) 
                        : 
                        (<Redirect
                            to={{
                                pathname: "/",
                                state: { from: props.location }
                            }}
                        />)
                    }
                />
            );
        }
    }

    const [loadingRoutes, setLoadingRoutes] = useState(false);    
    const [verifyUserConnect, setVerifyUserConnect] = useState(false);
    const [searchDataUser, setSearchDataUser] = useState(true);
    const [loadingDataUser, setLoadingDataUser] = useState(true);
    const [socketInit, setSocketInit] = useState(false);

    useEffect(() => {

        let categories = state.navbarCategories;
        let numberCategories = categories.length;

        async function loadCategories(){
            //listar categorias
            let url = '/es/ecommerce/api/categories/';
            if(numberCategories === 0){
                await axios.get(url,{crossDomain: true})
                .then(async (res) => {
                    console.log(res);
                    await setCategoriesList(dispatch, res.data);
                    //Indica que las categorias ya fueron cargadas y renderiza la app
                    //console.log('Todo cargado');
                }).catch(error => {
                    console.log(error)
                });
            }
        }  

        async function loadUserData(){
            setSearchDataUser(false);
            console.log('buscando datos del usuario');
            let urlUserData = '/es/profile/api/me/';
            axios({
                url: urlUserData,
                method: 'get'
            }).then(async (res) => {
                console.log(res.data);
                set_user_data(dispatch, res.data);
            }).catch((err) => {
                console.error(err);
            });
        }  

        if(state.is_loged){
            axios.defaults.baseURL = 'https://publipaid.com/'
            axios.defaults.headers.common = {'Authorization': `Bearer ${state.accessTkn}`}
            
            if(searchDataUser){
                loadUserData();
            }else{
                setLoadingDataUser(false);
            }

            console.log('logueado');
            if(state.userdata){
                if(state.userdata.chat_credentials){
                    console.log('get user data');
                    if(!socketInit){
                        setSocketInit(true);
                        //console.log(state.userdata.length);
                        //console.log(state.userdata);
                        //const socket = io(state.userdata.chat_credentials.chat_url);
                        
                        Socket = io(state.userdata.chat_credentials.chat_url);
                        let username = state.userdata.chat_credentials.uid;
                        let accessToken = state.userdata.chat_credentials.access_token;
        
                        Socket.on('connect', () => {
                            // console.log('you have been connected!');
                            if (!Socket.username && username) {
                                Socket.emit('publipaid login', username, accessToken);
                                console.log('Credentials sent!');
                            }
                        });
                        
                        /*
                            Socket.on('publipaid authenticated', (data) => {
                                var message = "Welcome to PubliPaid Chat – ";
                                console.log(message, data.userId);
                            });
                            Socket.on('publipaid user joined', (data) => {
                                console.log(data.userId + ' joined', data);
                            });
                        */
                    }
                }
            }

            if(!loadingRoutes){
                setLoadingRoutes(true);
                loadCategories();
            }

        }else{
            console.log('no estas logueado');
        }
    });
    
    return(
        <App>
            <Switch>
                <PrivateRoute exact path='/login'                               Needlogin={false} component={Login} />
                
                {/*Rutas privadas*/}
                <PrivateRoute exact path='/logout'                                    Needlogin={true} component={Logout} />
                
                <PrivateRoute exact path='/'                                    Needlogin={true} component={Home} />
                <PrivateRoute exact path='/sell-now/'                           Needlogin={true} component={SellNow} />
                <PrivateRoute exact path='/my-shopping/'                        Needlogin={true} component={MyPurchases} />
                <PrivateRoute exact path='/my-sales/'                           Needlogin={true} component={MySales} />
                <PrivateRoute exact path='/my-advertisement/:id'                Needlogin={true} component={SeeAdSeller} />
                <PrivateRoute exact path='/my-advertisement/:id/edit/'          Needlogin={true} component={EditAdvertisement} />
                <PrivateRoute exact path='/advertisement/:id'                   Needlogin={true} component={SeeProduct} />
                <PrivateRoute exact path='/advertisement/:id/purchase/'         Needlogin={true} component={Purchase} />
                <PrivateRoute exact path='/:category'                           Needlogin={true} showPriceFilters={true} component={Category} />
                <PrivateRoute exact path='/:category/:subcategory'              Needlogin={true} component={SubCategory} />
            </Switch>
        </App>
    );
}

export {AppRouter, Socket};
