import React, {useState, useEffect, Fragment} from 'react'
import {Link, withRouter, useLocation} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';

import './home.css';

import ImageProduct1 from '../../../../assets/market/home/mockup/1.png';

import IconNotFound from '../../../../assets/product-not-found/icon.png';
import SearchBar from '../../../helpers/searchbar';
import TabsMobile from '../../../helpers/tabsMobile';
import ModalCategories from '../modalCategories';
import LoaderInline from '../../../helpers/loader/LoaderInline';

//socket.io
import { Socket } from '../../../router/AppRouter';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Home(props) {
    const [showModalNotProductsFound, setShowModalNotProductsFound] = useState(false);
    const handleCloseModalNotProductsFound = () => setShowModalNotProductsFound(false);

    const [adsInCategories, setAdsInCategories] = useState([]);
    const [search, setSearch] = useState(false);
    const [loading, setLoading] = useState(true);

    const [textSearch, setTextSearch] = useState('');
    const [adsBySearch, setAdsBySearch] = useState([]);

    let query = useQuery();

    const getAdvertisementsBySearch = (searchData) => {
        if(!loading){
            setLoading(true);
        }

        let url = '/es/ecommerce/api/advertisements/?search='+searchData;

        axios({
            method: 'get',
            url
        }).then((res) => {
            //console.log(res.data);

            setTextSearch(searchData);
            setAdsBySearch(res.data);
            setLoading(false);

        }).catch((err) => {
            console.error(err);
        });
    }

    useEffect(() => {
        if(!search){
            setSearch(true);

            if(query.get('search') !== null && query.get('search') !== ''){
                getAdvertisementsBySearch(query.get('search'));
            }else{
                let url = '/es/ecommerce/api/advertisements/category-parent/';
                axios({
                    method: 'get',
                    url
                }).then((res) => {
                    //console.log(res.data);
                    setAdsInCategories(res.data);
                    setLoading(false);
                }).catch((err) => {
                    console.error(err);
                });
            }
        }else{
            //console.log(props.location.pathname);
            //console.log(props.location.search);

            if(textSearch !== null && textSearch !== '' && props.location.search === ''){
                setTextSearch('');
                setLoading(true);
                setSearch(false);
            }
        }
    });


    const handleSearch = (textInputSearch) => {
        //getAdvertisementsBySearch(textInputSearch);

        setTextSearch(textInputSearch);

        if(textInputSearch !== '' && textInputSearch !== null){
            props.history.push('/?search='+textInputSearch);
        }else{
            props.history.push('/');
        }

        setLoading(true);
        setSearch(false);
    }

    return (
        <div id="market">
            <TabsMobile />
            {(loading) &&
                <Fragment>
                    <SearchBar defaultValue={textSearch} handleSearch={handleSearch} isDisabled={loading} />
                    <LoaderInline />
                </Fragment>
            }
            {(!loading) && 
                <Fragment>
                    <SearchBar defaultValue={textSearch} handleSearch={handleSearch} />
                    <ModalCategories />
                    {(textSearch !== '') &&
                        <Fragment>
                            <h4 className="h6 mt-4">Mostrando resultados para: <span className="font-weight-bold">{textSearch}</span></h4>
                            {(adsBySearch.results && adsBySearch.results.length > 0) &&
                                <div className="row align-items-stretch category-product-list">
                                    <Fragment>
                                        {adsBySearch.results.map((item,key) => {
                                        return <div key={key} className="col-sm-6 col-md-4 mt-4 col-6 product">
                                                    <Link to={'/advertisement/'+item.id} className="card h-100 w-100">
                                                        <div className="product-image" style={{backgroundImage: 'url('+((item.photos.length > 0) ? item.photos[0].file : '')+')'}}>
                                                        </div>
                                                        <div className="card-body">
                                                            <h3 className="product-title h7 text-primary">{item.title}</h3>
                                                            <h3 className="h6 text-success font-weight-bold product-price">US$ {Number(item.amount).toFixed(2)}</h3>
                                                            <p className="text-muted mb-1"><i className="mr-2 fa fa-clock-o"></i>
                                                                Publicado {moment(item.date_published).fromNow()}
                                                            </p>
                                                            <p className="text-muted mb-1"><i className="mr-2 fa fa-map-marker"></i>{item.location}.</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                        })}
                                    </Fragment>
                                </div>
                            }

                            {(adsBySearch.results && adsBySearch.results.length === 0) &&
                                <div className="products-not-found text-center mt-4">
                                    <h3><i className="fa fa-warning"></i></h3>
                                    <h6 className="mt-3">No se encontraron resultados</h6>
                                </div>
                            }
                        </Fragment>
                    }
                    {(textSearch === '') &&
                        <Fragment>
                            <div className="categories-product-list">
                                {adsInCategories && adsInCategories.length > 0 && adsInCategories.map((item,key) => {
                                var numberItem = 0;
                                return <Fragment key={key}>
                                    {(item.latest_ads && item.latest_ads.length > 0) &&
                                            <div className="category-product-content mt-4">
                                                    <div className="row top-category align-items-center">
                                                        <div className="col-6">
                                                        <h6 className="mb-0 font-weight-bold category-title">{item.title}</h6>
                                                        </div>
                                                        <div className="col-6 text-right">
                                                            <Link to={'/'+item.slug} className="font-weight-bold text-primary">Ver todo <i className="fa fa-arrow-right ml-1"></i></Link>
                                                        </div>
                                                    </div>
                                                        <div className="row align-items-stretch category-product-list">
                                                            {item.latest_ads.map((ad,keyadd) => {
                                                            numberItem++;
                                                            return  <div key={keyadd} className={((numberItem === 3) ? 'd-none d-md-block col-sm-6 col-md-4 mt-4 col-6 product' : 'col-sm-6 col-md-4 mt-4 col-6 product')}>
                                                                        <Link to={'/advertisement/'+ad.id} className="card h-100 w-100">
                                                                            <div className="product-image" style={{backgroundImage: 'url('+((ad.photos.length > 0) ? ad.photos[0].file : '')+')'}}>
                                                                            </div>
                                                                            <div className="card-body">
                                                                                <h3 className="product-title h7 text-primary">{ad.title}</h3>
                                                                                <h3 className="h6 text-success font-weight-bold product-price">US$ {Number(ad.amount).toFixed(2)}</h3>
                                                                                <p className="text-muted mb-1"><i className="mr-2 fa fa-clock-o"></i>
                                                                                    Publicado  {moment(ad.date_published).fromNow()}
                                                                                </p>
                                                                                <p className="text-muted mb-1"><i className="mr-2 fa fa-map-marker"></i>{ad.location}.</p>
                                                                            </div>
                                                                        </Link>
                                                                    </div>    
                                                            })}
                                                        </div>
                                                </div>
                                                }
                                        </Fragment>
                                })}
                            </div>
                        </Fragment>
                    }
                </Fragment>
            }
            
            <Modal className="modal-market-pp-one" show={showModalNotProductsFound} onHide={handleCloseModalNotProductsFound}>
                <Modal.Header className="text-center" closeButton>
                    <Modal.Title as="h3" className="d-block pt-4 w-100 text-center font-weight-bold h4">El artículo ya no está disponible</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="product-not-found">
                        <div className="row pb-4">
                            <div className="col-5 col-sm-4">
                                <img src={IconNotFound} className="fluid-in-phones" alt=""/>
                            </div>
                            <div className="col-7 col-sm-8">
                                <h6 className="mb-2 small-in-phones text-lightgray">Es posible que se haya vendido o la publicación haya caducado. Mira estos otros artículos.</h6>
                                <button className="btn text-underline font-weight-bold btn-link p-0 m-0" onClick={handleCloseModalNotProductsFound}>Explorar Mercado</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default withRouter(Home);
