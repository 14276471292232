import React from 'react'

function Rules() {
    return (
        <div className="mt-4">
            <div className="w-100 bg-graypp px-3 py-4">
                <h3 className="font-weight-bold text-center mb-4 fs-p">Preguntas frecuentes sobre compra y venta de artículos</h3>
                <ol>
                    <li>
                        <h4 className="ml-2 font-weight-bold fs-p">¿El vendedor / comprador no responde?</h4>
                        <p>Si no puedes contactar al vendedor una vez cumplido el plazo de 24 horas, la operación se cancela automáticamente y tu dinero será acreditado en tu saldo disponible. Si no puedes contactar al comprador, puedes cancelar la operación o venta del artículo.</p>
                    </li>
                    <li>
                        <h4 className="ml-2 font-weight-bold fs-p">¿Por error, confirmaste el envío del artículo?</h4>
                        <p>Si por error confrimas el envío de un artículo, puedes cancelar la operación o venta del artículo, el dinero será devuelto al comprador.</p>
                    </li>
                    <li>
                        <h4 className="ml-2 font-weight-bold fs-p">¿Qué puedo hacer si el vendedor no envía el artículo?</h4>
                        <p>Si el vendedor no envía el articulo, puedes iniciar una disuta, el vendedor será expulsado de Publipaid, el comprador podrá recuperar su dinero ya que se encuentra como fondo de garantía. El vendedor no debe liberar el saldo si no recibe el producto.</p>
                    </li>
                    <li>
                        <h4 className="ml-2 font-weight-bold fs-p">¿Qué puedo hacer si el comprador recibe el artículo y no libera el saldo?</h4>
                        <p>Si el comprador no libera el saldo una vez recibido el artículo, será expulsado y/o penalizado, nuestro departamento de fraude revisará el caso y acreditará el dinero en la cuenta del vendedor.</p>
                    </li>
                    <li>
                        <h4 className="ml-2 font-weight-bold fs-p">¿Cómo solicitar la devolución de mi dinero?</h4>
                        <p>Si el artículo esta dañado o no corresponde a la descrición del anuncio puedes llegar a un acuerdo con el vendedor y solicitar la anulación de la operación para que el saldo o dinero en garantía se acredite en la cuenta del comprador. El comprador pagará los gastos de la devolución del artículo. El vendedor solo puede cancelar la operación una vez reciba la devolución de su artículo. El vendedor solo puede cancelar la operación una vez reciba la devolución de su artículo.</p>
                    </li>
                </ol>
            </div>
        </div>
    )
}

export default Rules;
