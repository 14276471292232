//modules
import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import {Link} from 'react-router-dom';

//styles
import '../../styles/footer.css';

export default function Footer(){
    return(
        <footer className="mt-4">
          <Container>
            <Row>
                <Col xs={12}>
                    <span className="copyright">Copyright © 2019 | Owloo LLC, Inc. S.A.. Todos los derechos reservados.</span>
                </Col>
                <Col xs={12}>
                    <ul className="list-inline menu-footer">
                        <li><Link to="/terms-and-contitions/">Términos y condiciones</Link></li>
                        <li><Link to="/privacy-policy/">Política de privacidad</Link></li>
                        <li><Link to="/refund-policy/">Política de reembolso</Link></li>
                        <li><Link to="/anti-spam-policy/">Política Anti-Spam</Link></li>
                    </ul>
                </Col>
            </Row>
          </ Container>
        </footer>
    )
}