import React from 'react';

export const Store = React.createContext();

const initialState = {
    data:{},
    loading: true,
    userdata: null,
    lang: 'es',
    allowedLanguages:['es', 'en'],
    navbarCategories:[],
    accessTkn: '',
    is_loged: true,
    priceFilters:{min:'',max:''}
}

function reducer(state, action) {
    switch (action.type) {
      case 'PAGE_LOADING':
        return {
          ...state, 
          loading: action.payload
      }
      case 'SET_ACCESS_TOKEN':
        return {
          ...state, 
          accessTkn: action.payload
      }
      case 'SET_USER_DATA':
        return {
          ...state, 
          userdata: action.payload
      }
      case 'SET_CATEGORIES':
      return{
        ...state,
        navbarCategories: action.payload
      }
      case 'PAGE_LOADED':
        return {
          ...state, 
          loading: action.payload
      }
      case 'SET_LANGUAGE':
        return {
            ...state, 
            lang: action.payload
      }
      case 'LOGIN':
        return {
            ...state, 
            is_loged: true
      }
      case 'LOGOUT':
        return {
            ...state, 
            is_loged: false
      }
      case 'SET_PRICE_FILTERS':
        return {
            ...state, 
            priceFilters: action.payload
      }
      default:
        return state;
    }
  }

export function StoreProvider (props) {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const value = {state, dispatch};
    
    return  <Store.Provider value={value}>
                {props.children}
            </Store.Provider>;
}
