import React, {useState, useEffect, Fragment, useContext} from 'react'

import axios from 'axios'
import moment from 'moment'
import SearchBar from '../../../helpers/searchbar'
import {withRouter, Link, Redirect} from 'react-router-dom';
import {Modal, Alert} from 'react-bootstrap';
import { Carousel } from 'react-responsive-carousel';

import {Store} from '../../../../storage/Store';

import "react-responsive-carousel/lib/styles/carousel.min.css";

import imageProductSlider from '../../../../assets/market/show-product-mockup/image.png';

import ImageProduct1 from '../../../../assets/market/home/mockup/1.png';
import ImageProduct2 from '../../../../assets/market/home/mockup/2.png';
import ImageProduct3 from '../../../../assets/market/home/mockup/3.png';
import LoaderInline from '../../../helpers/loader/LoaderInline';
import TabsMobile from '../../../helpers/tabsMobile';
import Purchase from '../purchase';

function SeeProduct(props) {
    const [showModalRank, setShowModalRank] = useState(false);
    const [showModalBuy, setShowModalBuy] = useState(false);

    const {state,dispatch} = useContext(Store);
    let categories = state.navbarCategories;
    let actualIDOnUrl = props.match.params.id;

    const [loading, setLoading] = useState(true);
    const [loadingRanks, setLoadingRanks] = useState(true);

    const [search, setSearch] = useState(true);
    const [searchRanks, setSearchRanks] = useState(true);

    const [paying, setPaying] = useState(false);
    const [paymentErrors, setPaymentErrors] = useState([]);

    const [id, setId] = useState(props.match.params.id);

    //datos del anuncio y opiniones del anuncio
    const [data, setAdvertisementData] = useState([]);
    const [dataRanks, setAdvertisementDataRanks] = useState([]);

    //status 
    const [status, setStatus] = useState('');

    const [category, setCategory] = useState([]);
    const [subCategory, setSubCategory] = useState([]);

    const openModalRank = () => {
        setShowModalRank(true);
        if(!loading && loadingRanks){
            let url = '/es/ecommerce/api/ratings/user/'+data.seller.id;
            axios({
                url,
                method: 'get'
            }).then((res) => {
                console.log(res.data);
                setLoadingRanks(false);
                setAdvertisementDataRanks(res.data);
            }).catch((err) => {
                console.error(err);
            })
        }
    }

    useEffect(() => {
        let url = '/es/ecommerce/api/advertisements/'+id+'/';
        //console.log('cargando producto:'+id);
        
        if(actualIDOnUrl === id){
            if(search){
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                setSearch(false);
                axios({
                    url,
                    method: 'get'
                }).then((res) => {
                    
                    console.log(res.data);
                    setAdvertisementData(res.data);
                    setStatus(res.data.status);

                    let idCategoryProduct = res.data.category;
                    let getCategory = {};
                    let getSubCategory = {};
                    categories.filter(cat => {
                        let subcategory = cat.subcategories;
                        let filter = subcategory.filter(sub => sub.id === idCategoryProduct);
                        if(filter.length){
                            getCategory = cat;
                            getSubCategory = filter[0];
                        }
                });

                    setCategory(getCategory);
                    setSubCategory(getSubCategory);
                    setLoading(false);

                }).catch((err) => {
                    console.log(err.response);
                });
            }
        }else{
            setId(actualIDOnUrl);
            setSearch(true);
            setLoading(true);
        }
    });

    const resetComponent = () => {
        setSearch(true);
        setLoading(true);
    }

    const handleCloseModalRank = () => {
        setShowModalRank(false);
    }

    const contactByWhatsappNumber = (e) => {
        e.preventDefault();
        let phoneWhitoutSimbol = data.phone.replace('+', '');
        //let url = 'https://web.whatsapp.com/send?phone='+584144406463;
        //https://faq.whatsapp.com/general/26000030/?lang=es
        let url = 'https://wa.me/'+phoneWhitoutSimbol;
        window.open(url, '_blank');
    }

    const confirmPayment = () => {
        let url = '/es/ecommerce/api/advertisements/'+id+'/confirm-purchase/';
        setPaying(true);
        setPaymentErrors([]);

        axios({
            url,
            method: 'put'
        }).then((res) => {
            console.log(res.data);
            setPaying(false);
            setSearch(true);
            setLoading(true);
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }).catch((err) => {
            console.error(err.response.data);
            setPaymentErrors(err.response.data);
            setPaying(false);
        });
    }

    var numberItem = 0;
    //console.log(paymentErrors);

    
    return (
        <div id="see-product" className="content-product">
            <TabsMobile />
            {(!loading) &&
                <Fragment>
                    {(data.status === 'L') && 
                            <Redirect
                                to={{
                                    pathname: "/my-sales/",
                                    state: {
                                        messageInfo: 'Anuncio: '+data.title+' ha sido eliminado'
                                    }
                                }}
                            />   
                    }
                    {(data.status === 'I') && 
                        <p className="mb-4 bg-graypp py-3 px-3"><span className="font-weight-bold mr-2">Estado de la transacción:</span>{data.status_name}</p>
                    }
                    {(data.status !== 'L') &&
                        <Fragment>
                            {(status !== 'P' && status !== 'E' && status !== 'I') && 
                                <Purchase resetComponent={resetComponent} status={status} data={data} />
                            }

                            {(status === 'P' || status === 'E' || status === 'I') && 
                            <Fragment>
                                <SearchBar isDisabled={loading} />
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb p-0 breadcrumb-pp">
                                        <li className="breadcrumb-item">
                                            <Link to={'/'+category.slug} className="font-weight-bold text-primary text-underline">
                                                {category.title}
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item active">
                                            <Link to={'/'+category.slug+'/'+subCategory.slug} className="font-weight-bold text-primary">
                                                {subCategory.title}
                                            </Link>
                                        </li>
                                    </ol>
                                </nav>
                                <h3 className="mt-4 font-weight-bold">{data.title}</h3>
                                <p className="data-post h7 mb-4 text-lightgray">
                                    <span><i className="fa fa-map-marker mr-2"></i>{data.location}</span>
                                    <span className="ml-3"><i className="fa fa-clock-o mr-2"></i>Publicado {moment(data.date_published).fromNow()}</span>
                                </p>
                                <p className="product-description mb-1">
                                    {data.description}
                                </p>
                                <p className="d-none">
                                    Haz clic aquí <a href="##" className="text-underline text-primary">https://m.me/alidcoronell...</a> ... <a href="##" className="text-underline text-primary">Ver mas</a>
                                </p>

                                {data.photos && data.photos.length > 0 &&
                                    <div className="mb-3 slider-product">
                                        <Carousel 
                                            statusFormatter={(current, total) => <span><i className="fa fa-camera mr-2"></i>{current} / {total}</span>} 
                                            emulateTouch 
                                            dynamicHeight 
                                            infiniteLoop 
                                            showIndicators={false} 
                                            showThumbs={false}
                                        >
                                            {data.photos.map((photo,key) => {
                                            return   <div key={key}>
                                                        <img alt={data.title+'photo'+photo.id} src={photo.file} />
                                                    </div>
                                            })}
                                        </Carousel>
                                    </div>
                                }

                                <div id="slider-product-images" className="mb-3 d-none">
                                    <img src={imageProductSlider} className="img-fluid w-100" alt=""/>
                                </div>

                                <div className="row align-items-center product-buy-data">
                                    <div className="col-lg-4 mt-2 mb-3 col-12">
                                        <h6 className="font-weight-bold text-primary m-0">Precio: US$ {Number(data.amount).toFixed(2)}</h6>
                                    </div>
                                    <div className="col-lg-4 text-lg-center mt-2 mb-3 col-sm-5 col-6">
                                        <button onClick={() => setShowModalBuy(true)} className="btn btn-text-xs-in-xs btn-full-xs fs-md-to-lg-p px-3 btn-success text-uppercase font-weight-bold">Comprar ahora</button>
                                    </div>
                                    <div className="col-lg-4 mt-2 mb-3 col-sm-7 col-6 text-sm-right">
                                        <button onClick={(e) => contactByWhatsappNumber(e)} className="btn btn-text-xs-in-xs fs-md-to-lg-p px-3 btn-full-xs btn-responsive-xs bg-graypp font-weight-bold">Contactar al vendedor <i className="fa fa-whatsapp text-success ml-1"></i></button>
                                    </div>
                                </div>

                                <p><span className="font-weight-bold">Devoluciones:</span> {(data.accept_returns) ? 'El comprador paga por la devolución del artículo' : 'No se aceptan devoluciones'}</p>
                                
                                {(data.seller) &&
                                    <Fragment>
                                        <h3 className="font-weight-bold fs-p">Información del vendedor</h3>
                                        <div className="seller-data-content">
                                        <div className="row mb-4">
                                        <div className="col-md-2 mb-2 col-12">
                                                ID
                                            </div>
                                            <div className="col-md-10 mb-2 col-12">
                                                {data.seller.id}
                                            </div>
                                            <div className="col-md-2 mb-2 col-12">
                                                Nombre
                                            </div>
                                            <div className="col-md-10 mb-2 col-12">
                                                {data.seller.full_name}
                                            </div>
                                            <div className="col-md-2 mb-2 col-12">
                                                WhatsApp/Celular
                                            </div>
                                            <div className="col-md-10 mb-2 col-12">
                                                {data.seller.phone}
                                            </div>
                                            <div className="col-md-2 mb-2 col-12">
                                                Ubicación
                                            </div>
                                            <div className="col-md-10 mb-2 col-12">
                                                {data.seller.location}
                                            </div>
                                            <div className="col-12">
                                                Se unió a Publipaid en {moment(data.seller.date_joined).format('DD/MM/YYYY')}
                                            </div>
                                        </div>
                                    </div>
                                    </Fragment>
                                }

                                {data.seller && data.seller.seller_rating_summary &&
                                    <div className="seller-ranking mb-4">
                                        <h3 className="font-weight-bold fs-p">Reputación del vendedor</h3>
                                        <ul className="rank-list">
                                            <li>
                                                <span className="rank-count font-weight-bold">{data.seller.seller_rating_summary.positive}</span>
                                                <div className="rank-icon good"></div>
                                                <span className="rank-count">Positivos</span>
                                            </li>
                                            <li>
                                                <span className="rank-count font-weight-bold">{data.seller.seller_rating_summary.neutral}</span>
                                                <div className="rank-icon normal"></div>
                                                <span className="rank-count">Neutrales</span>
                                            </li>
                                            <li>
                                                <span className="rank-count font-weight-bold">{data.seller.seller_rating_summary.negative}</span>
                                                <div className="rank-icon bad"></div>
                                                <span className="rank-count">Negativos</span>
                                            </li>
                                        </ul>
                                        {(data.seller.seller_rating_summary.positive > 0 || data.seller.seller_rating_summary.neutral > 0 || data.seller.seller_rating_summary.negative > 0) &&
                                            <button onClick={() => openModalRank()} className="btn-link btn p-0 fs-p">Ver opiniones sobre el vendedor</button>
                                        }
                                        </div>
                                }
                                {(data.relateds && data.relateds.length > 0) &&
                                    <div className="more-products">
                                        <h3 className="fs-p font-weight-bold">Artículos que le podrían interesar</h3>
                                        <div className="row align-items-stretch category-product-list">
                                            {data.relateds.map((ad,keyadd) => {
                                                numberItem++;
                                                return  <div key={keyadd} className={((numberItem === 3) ? 'd-none d-md-block col-sm-6 col-md-4 mt-4 col-6 product' : 'col-sm-6 col-md-4 mt-4 col-6 product')}>
                                                            <Link to={'/advertisement/'+ad.id} className="card h-100 w-100">
                                                                <div className="product-image" style={{backgroundImage: 'url('+((ad.photos.length > 0) ? ad.photos[0].file : ImageProduct1)+')'}}>
                                                                </div>
                                                                <div className="card-body">
                                                                    <h3 className="product-title h7 text-primary">{ad.title}</h3>
                                                                    <h3 className="h6 text-success font-weight-bold product-price">US$ {Number(ad.amount).toFixed(2)}</h3>
                                                                    <p className="text-muted mb-1"><i className="mr-2 fa fa-clock-o"></i>
                                                                        Publicado {moment(ad.date_published).fromNow()}
                                                                    </p>
                                                                    <p className="text-muted mb-1"><i className="mr-2 fa fa-map-marker"></i>{ad.location}.</p>
                                                                </div>
                                                            </Link>
                                                        </div>    
                                            })}
                                        </div>
                                    </div>
                                }

                                <Modal show={showModalRank} onHide={handleCloseModalRank}>
                                    <Modal.Header className="py-2" closeButton>
                                        <Modal.Title as="h3" className="h7 font-weight-bold mt-1 mb-0">Comentarios para (ID {data.seller.id}) {data.seller.full_name}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="py-2">
                                        {data.seller && data.seller.seller_rating_summary &&
                                            <div className="seller-ranking">
                                                <ul className="rank-list">
                                                    <li>
                                                        <span className="rank-count font-weight-bold">{data.seller.seller_rating_summary.positive}</span>
                                                        <div className="rank-icon good"></div>
                                                        <span className="rank-count">Positivos</span>
                                                    </li>
                                                    <li>
                                                        <span className="rank-count font-weight-bold">{data.seller.seller_rating_summary.neutral}</span>
                                                        <div className="rank-icon normal"></div>
                                                        <span className="rank-count">Neutrales</span>
                                                    </li>
                                                    <li>
                                                        <span className="rank-count font-weight-bold">{data.seller.seller_rating_summary.negative}</span>
                                                        <div className="rank-icon bad"></div>
                                                        <span className="rank-count">Negativos</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </Modal.Body>
                                    <Modal.Footer className="justify-content-start align-items-start">
                                        

                                            {(loadingRanks) &&
                                                <div className="w-100 d-flex justify-content-center">
                                                    <LoaderInline />
                                                </div>
                                            }

                                            {(!loadingRanks) &&
                                                <ul className="qualification-list-comments">
                                                    {dataRanks && dataRanks.results && dataRanks.results.map((item, index) => {
                                                        let rating = item.rating;
                                                        let ratingName = '';

                                                        if(rating === 'P'){
                                                            ratingName = 'good';
                                                        }

                                                        return <li key={index}>
                                                                    <p className="qualification-user-data mb-1 font-weight-bold"><span className="qualification-rank rank-icon good"></span>ID: {item.rater_user.id} {item.rater_user.full_name} - {moment(item.date_created).format('DD MMMM YYYY')}</p>
                                                                    <p className="qualification-message mb-1 text-lightgray">{item.comments}</p>
                                                                    <p className="qualification-buy-data h7 mb-4 font-weight-bold">Compra de artículo por US$ <span className="text-success f-bold">{Number(item.adv_amount).toFixed(2)}</span></p>
                                                                </li>
                                                    })}
                                                </ul>
                                            }

                                            <button className="d-none btn btn-link text-primary font-weight-bold p-0 fs-p mt-1">Ver más comentarios</button>
                                        
                                    </Modal.Footer>
                                </Modal>
                                <Modal className="modal-market-pp-one modal-vcenter" show={showModalBuy} onHide={() => setShowModalBuy(false)}>
                                <Modal.Header className="text-center" closeButton>
                                    <Modal.Title as="h3" className="d-block pt-4 w-100 text-center font-weight-bold h4">Revisa y confirma tu compra</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="product-not-found">
                                        {(paymentErrors.non_field_errors) &&
                                            <Alert variant="danger" className="mb-4">
                                                <span className="font-weight-bold">Error:</span> {paymentErrors.non_field_errors[0]}
                                            </Alert>
                                        }
                                        <div className="row">
                                            <div className="col-5 col-sm-5">
                                                <img src={((data.photos.length > 0) ? data.photos[0].file : imageProductSlider)} className="img-fluid" alt=""/>
                                            </div>
                                            <div className="col-7 col-sm-7">
                                                <h6 className="mb-2 small-in-phones text-lightgray">{data.title}</h6>
                                                <h6 className="mb-2 small-in-phones font-weight-bold">US$ {Number(data.amount).toFixed(2)} (saldo publipaid)</h6>
                                            </div>
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer className="pt-2">
                                    <button onClick={() => confirmPayment()}
                                        disabled={paying}
                                        className="btn px-4 fs-p font-weight-bold btn-success">
                                        {(!paying) ? 'Confirmar compra' : <i className="fa fa-spin fa-spinner"></i>}
                                    </button>
                                    <button onClick={() => setShowModalBuy(false)} className="btn px-4 fs-p font-weight-bold bg-graypp">Cancelar</button>
                                </Modal.Footer>
                            </Modal>
                            </Fragment>
                            }
                        </Fragment>
                    }
                </Fragment>
            }

            {(loading) &&
                <LoaderInline />
            }
        </div>
    )
    
}

export default withRouter(SeeProduct);