//modules
import React, {useContext, useEffect, useState} from 'react';

//storage 
import {Store} from '../storage/Store';
import {
    setLanguage, 
    pageLoaded
    //, loginUser
} from '../storage/Actions';

//translate
import { TranslatorProvider } from "react-translate";

export default function Translator(props){
    const {state, dispatch} = useContext(Store);
    let language = state.lang;

    //localstorage
    
    //lang
    let STORAGE_LANGUAGE = localStorage.getItem('language');
    //comprobar si el usuario esta logeado
    let STORAGE_ISLOGED = localStorage.getItem('is_loged');

    let basename = (state.is_loged ? 'marketplace/' : '');

    useEffect(() => {

        console.log('verificandoooooooooooo');
        console.log(basename);
        
        //COMPONENTE UNICAMENTE PARA SETEAR EL LENGUAJE
        //console.log(STORAGE_LANGUAGE);

        /*
        if(STORAGE_ISLOGED === null){
            localStorage.setItem('is_loged', true);
        }
        loginUser(dispatch);
        */
        /*
        if(STORAGE_ISLOGED === 'true'){
            loginUser(dispatch);
        }else if(STORAGE_ISLOGED === 'false'){
            handleLogout(dispatch);
        }*/

        //let subfolder = (state.is_loged ? 'marketplace/' : '');

        let subfolder =  basename;
        let actualpath = window.location.pathname;
        let actualpathWithFolder = actualpath.substring(subfolder.length, subfolder.length + 1);
        //numero de caracteres del path actual

        let languagepath = '';
        let languageInPath = '';

        let lengthPath = actualpath.length;

        languagepath = window.location.pathname.substring(0,4);
        languageInPath = window.location.pathname.substring(1,3);

        //Buscando el lenguaje seteado coincide con el lenguaje por defecto del storage:"/es"
        let validateLanguage = languagepath.search('/'+state.lang);
        

        
                
                console.log('storage language: '+language);
                console.log('path actual: '+actualpath);
                console.log('path.length: '+lengthPath);

                console.log('lenguaje en el path: '+languageInPath);
                console.log('El lenguaje en el path '+((validateLanguage < 0) ? 'no coincide con: '+language : 'si coincide con: '+language));
                console.log('_____');

                if(validateLanguage < 0){
                    //No encontro coincidencias con el state.lang
                    console.log('Sin coincidencias.. \ntraduciendo al nuevo lenguaje: '+ languageInPath);

                    //Se debe evaluar ademas si no existe ya un lenguaje seteado por url y si ademas solo se esta enviando dicho lenguaje
                    if( ((lengthPath >= 3 && lengthPath <= 4) && state.allowedLanguages.includes(languageInPath)) || state.allowedLanguages.includes(languageInPath)){
                        //Se actualiza el storage
                        localStorage.setItem('language', languageInPath);
                        setLanguage(dispatch, languageInPath);
                        //console.log('_______________________');
                        //console.log('traducido');
                        
                    }else if(actualpath === '/' || actualpath === ''){
                        if(STORAGE_LANGUAGE === null){
                            //console.log('No se ha encontrado ningun tipo de lenguaje admitido en el storage');
                            localStorage.setItem('language', language);
                            setLanguage(dispatch, language);
                            pageLoaded(dispatch);
                        }else{
                            //console.log('Traduciendo');
                            if(state.allowedLanguages.includes(STORAGE_LANGUAGE)){
                                window.history.replaceState({}, 'language_default_config', '/'+((STORAGE_LANGUAGE) ? STORAGE_LANGUAGE : language)+'/'+subfolder+actualpathWithFolder);
                                setLanguage(dispatch, STORAGE_LANGUAGE);
                                pageLoaded(dispatch);
                            }else{
                                setLanguage(dispatch, language);
                                pageLoaded(dispatch);
                            }
                        }
                    }else{
                        //si no hay lenguaje alguno en el path y se quiere cargar una url por defecto
                        console.log('actual path:'+ actualpathWithFolder);
                        console.log('No hay lenguaje definido, seteando lenguaje');
                        window.history.replaceState({}, 'language_default_config', '/'+((STORAGE_LANGUAGE) ? STORAGE_LANGUAGE : language)+'/'+subfolder+actualpathWithFolder);
                        setLanguage(dispatch, ((STORAGE_LANGUAGE) ? STORAGE_LANGUAGE : language));
                        pageLoaded(dispatch);
                    }
                }else{
                    if(subfolder !== ''){
                        let actualpathInLogin = actualpath.substring(subfolder.length + 4, actualpath.length);
                        console.log(actualpathInLogin);
                        window.history.replaceState({}, 'language_default_config', '/'+((STORAGE_LANGUAGE) ? STORAGE_LANGUAGE : language)+'/'+subfolder+actualpathInLogin);
                    }
                    localStorage.setItem('language', languageInPath);
                    pageLoaded(dispatch);
                }
    }, [state.loading, state.is_loged, language, dispatch, state.lang, state.allowedLanguages, STORAGE_LANGUAGE,STORAGE_ISLOGED]);

    const render = ((!state.loading) ?
        <TranslatorProvider translations={require('./i18n/'+language+'.js').default}>
            {/*Router*/}
            {props.children}
        </TranslatorProvider>
    :
        <React.Fragment>
            ''
        </React.Fragment>
    );
   
    return render;
}