//modules
import React from 'react';

//components
import Component from './core/Component';

//storage
//import {Store} from './../storage/Store';

function App(props) {
  //const {state} = useContext(Store);

  /*
  if(!state.loading){
    //console.log(process.env.REACT_APP_RECAPTCHA_KEY);
  }*/

  /*
  var app = ((!state.loading) 
            ?
              <React.Fragment>
                  <Component body={props.children} />
              </React.Fragment>
            :
              ''
  );*/

  var app  = <React.Fragment>
    <Component body={props.children} />
  </React.Fragment>;

  return (
    <div id="app">
      {app}
    </div>
  );
}

export default App;
