import React, {useState, useEffect, Fragment} from 'react';
import axios from 'axios';
import moment from 'moment';
import {OverlayTrigger, Tooltip } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import TabsMobile from '../../../helpers/tabsMobile';
import LoaderInline from '../../../helpers/loader/LoaderInline';
import InfiniteScroll from 'react-infinite-scroll-component';

function MyPurchases() {
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState(true);
    const [list, setList] = useState([]);
    const [urlNext, setUrlNext] = useState('');
    const [data, setData] = useState([]);

    useEffect(() => {
        if(loading){
            let url = '/es/ecommerce/api/my-purchases/';
            if(search){
                setSearch(false);
                console.log('load my sales');

                axios({
                    url,
                    method: 'get'
                }).then((res) => {
                    console.log(res);

                    setData(res.data);
                    setList(res.data.results);
                    setUrlNext(res.data.next);

                    setLoading(false);
                }).catch((err) => {
                    console.error(err);
                });
            }
        }
    });

    const fetchData = () => {
        console.log('buscando por la nueva pagina:'+ urlNext);
        let actualList = list;
        console.log(actualList);

        axios({
            method: 'get',
            url: urlNext
        }).then((res) => {

            console.log(res.data);
            for(var i=0; i<res.data.results.length; i++){
                console.log(res.data.results[i]);
                actualList.push(res.data.results[i]);
            }

            console.log(actualList);
            setList(actualList);
            setUrlNext(res.data.next);

        }).catch((err) => {
            console.error(err);
        });
    }

    let moreData = urlNext !== null && urlNext !== '';

    return (
        <div>
            <TabsMobile />
            <h2 className="h6 font-weight-bold mb-4">Mis compras</h2>
            <div>
                {(loading) && 
                    <LoaderInline />
                }
                {(!loading) &&
                    <InfiniteScroll
                        dataLength={list.length}
                        hasMore={moreData}
                        next={fetchData}
                        loader={<div className="text-center mt-5 h3"><i className="fa fa-spin fa-spinner"></i></div>}
                        //endMessage={<p className="text-center">Sin mas registros</p>}
                    >
                        <div className="table-responsive table-center text-center">
                            <table className="table-ads table-striped table-hover table">
                            <thead>
                                <tr>
                                    <th>Operación#</th>
                                    <th className="d-sm-datatable-none">Creado en</th>
                                    <th className="d-sm-datatable-none">Vendedor</th>
                                    <th className="d-sm-datatable-none">Precio</th>
                                    <th>Artículo</th>
                                    <th>Estado</th>
                                    <th className="d-sm-datatable-none">Calificación</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(list.length > 0) &&
                                <Fragment>
                                    {list.map((item, index) => {
                                        let classIcon = '';

                                        if(item.rating_by_seller){
                                            if(item.rating_by_seller.rating === '0'){
                                                classIcon = 'normal';
                                            }else if(item.rating_by_seller.rating === 'N'){
                                                classIcon = 'bad';
                                            }else if(item.rating_by_seller.rating === 'P'){
                                                classIcon = 'good';
                                            }
                                        }

                                        return (<tr key={index}>
                                            <td>
                                                <div className="w-25-table-sm"></div>
                                                <Link to={'/advertisement/'+item.id} className="text-uppercase btn btn-link px-0 text-primary font-weight-bold fs-p">{item.id} - ver</Link>
                                                {(Number(item.unread_chat_messages_count) > 0) &&
                                                    <p>
                                                        <span className="badge mb-0 badge-danger">{item.unread_chat_messages_count}</span>
                                                    </p>
                                                }
                                                <span className="d-md-none d-block">{moment(item.date_created).format('DD/MM/YYYY')}</span>
                                                <div className="d-md-none d-block">
                                                    {(item.seller) &&
                                                        <Fragment>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        {(item.seller.is_online) ? 'En linea' : 'Offline'}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <i className={"fa fa-male user mr-1 "+ ((item.seller.is_online) ? 'online' : '')}></i>
                                                            </OverlayTrigger>
                                                            {item.seller.id}
                                                            {item.seller.rank !== null &&
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        Miembro {item.seller.rank_name}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <i className={"ml-1 rank-bullet--"+ item.seller.rank}></i>
                                                            </OverlayTrigger>
                                                            }
                                                            
                                                        </Fragment>
                                                    }
                                                </div>
                                            </td>
                                            <td className="d-sm-datatable-none">{moment(item.date_created).format('DD/MM/YYYY')}</td>
                                            <td className="d-sm-datatable-none">
                                                <div style={{minWidth: '85px'}}>
                                                    {(item.seller) &&
                                                        <Fragment>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        {(item.seller.is_online) ? 'En linea' : 'Offline'}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <i className={"fa fa-male user mr-1 "+ ((item.seller.is_online) ? 'online' : '')}></i>
                                                            </OverlayTrigger>
                                                            {item.seller.id}

                                                            {item.seller.rank !== null &&
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip>
                                                                        Miembro {item.seller.rank_name}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <i className={"ml-1 rank-bullet--"+ item.seller.rank}></i>
                                                            </OverlayTrigger>
                                                            }

                                                        </Fragment>
                                                    }
                                                </div>
                                            </td>
                                            <td className="d-sm-datatable-none text-success font-weight-bold"><div style={{minWidth: '75px'}}>USD$ {Number(item.amount).toFixed(2)}</div></td>
                                            <td>
                                                <div className="w-50-table-sm">
                                                    <div>{item.title}</div>
                                                    <div className="d-md-none d-block text-success font-weight-bold">USD$ {Number(item.amount).toFixed(2)}</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="w-25-table-sm">
                                                    {item.status_name}
                                                    {(item.rating_by_seller) &&
                                                        <span style={{height: '22px', float: 'inherit'}} className={"mt-2 d-md-none d-block qualification-rank rank-icon mx-auto " + classIcon}></span>
                                                    }
                                                </div>
                                            </td>             
                                            <td className="d-sm-datatable-none text-center">
                                                {(item.rating_by_seller) &&
                                                    <span style={{height: '22px', float: 'inherit'}} className={"qualification-rank rank-icon mx-auto " + classIcon}></span>
                                                }
                                                </td>
                                        </tr>)
                                    })}
                                </Fragment>
                                }

                                {(list.length === 0) &&
                                    <tr>
                                        <td colSpan="7" className="text-center">
                                            <i className="fa fa-warning mr-3"></i>
                                            No ha realizado ninguna compra
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        </div>
                    </InfiniteScroll>
                }
            </div>
        </div>
    )
}

export default MyPurchases;
