import React, {useState, useEffect, Fragment} from 'react'

import Rules from '../../../helpers/buy-sell-rules';
import {Modal } from 'react-bootstrap';
import moment from 'moment';
import axios from 'axios';
import RatingChoices from '../../../helpers/ratingChoices';
import Chat from '../../../helpers/chat';

function BuyerPurchase(props) {

    const [status, setStatus] = useState(props.status);
    const [data, setData] = useState(props.data);

    const [termOne, setTermOne] = useState(false);
    const [termTwo, setTermTwo] = useState(false);
    const [termThree, setTermThree] = useState(false);

    const [sendAmount, setSendAmount] = useState(false);
    const [showModalPay, setShowModalPay] = useState(false);

    const [releaseAmountErrors, setReleaseAmountErrors] = useState([]);

    useEffect(() => {
        //console.log(props.data); 
    });

    const releaseAmount = (e) => {
        e.preventDefault();
        let url = '/es/ecommerce/api/advertisements/'+data.id+'/release-amount/';

        setSendAmount(true);

        axios({
            url,
            method: 'put'
        }).then((res) => {
            console.log(res.data);
            setSendAmount(false);
            props.resetComponent();
        }).catch((err) => {
            console.error(err);
            setSendAmount(false);
        });
    }

    const contactByWhatsappNumber = (e) => {
        e.preventDefault();
        let phoneWhitoutSimbol = data.phone.replace('+', '');
        //let url = 'https://web.whatsapp.com/send?phone='+584144406463;
        //https://faq.whatsapp.com/general/26000030/?lang=es
        let url = 'https://wa.me/'+phoneWhitoutSimbol;
        window.open(url, '_blank');
    }

    return (
        <div>
            <div className="d-none mb-4">
                <button onClick={() => setStatus('R')}>Liberado</button>
                <button onClick={() => setStatus('C')}>Enviado</button>
                <button onClick={() => setStatus('S')}>Confirmar</button>
                <button onClick={() => setStatus('T')}>Cancelar</button>
                <button onClick={() => setStatus('cad')}>Caducado</button>
            </div>

            <p className="mb-4">
                <a href="##" className="text-primary mr-2">Operación #{data.id}:</a> {data.title} - US$ {Number(data.amount).toFixed(2)}
            </p>

            <p className="mb-4 bg-graypp py-3 px-3"><span className="font-weight-bold mr-2">Estado de la transacción:</span>{data.status_name}</p>
            
            <div className="row">
                <div className="col-md-6">
                    <Chat 
                        id={data.id}
                        messages={(data.chat_messages ? data.chat_messages : [])}
                        otherUser={data.seller}
                    />
                </div>
                <div className="col-md-6">
                    <div className="w-100 bg-graypp p-3">
                        {(status === 'T' || status === 'D') &&
                            <div>
                                <h4 className="font-weight-bold fs-p">Información de la Transacción</h4>
                                <p>Esta operación comercial se cancelo el {moment(data.date_cancelled).format('DD/MM/YYYY hh:mm:ss')} y fue cancelada por el vendedor, todavia puede enviar mensajes al <span className="text-primary">vendedor.</span></p>
                            </div>
                        }
                        {(status === 'I' || status === 'X') &&
                            <div>
                                <h4 className="font-weight-bold fs-p">Información de la Transacción</h4>
                                <p>Esta operación comercial caduco, de manera automatica, por falta de confirmacion del envío del artículo por parte del vendedor. </p>
                                <p>Esto ocurre cuando el vendedor no cumple con enviar el artículo al comprador en plazo definido en la transacción.</p>
                            </div>
                        }
                        {(status === 'R') &&
                            <div>
                                <h4 className="font-weight-bold fs-p">Información de la Transacción</h4>
                                <h4 className="font-weight-bold fs-p">Operación completada</h4>
                                <p>Esta operacion comercial se cerro el {moment(data.date_amount_released).format('DD/MM/YYYY hh:mm:ss')}</p>
                                <p>Todavia puede enviar mensajes al vendedor</p>
                            </div>
                            }
                        {(status === 'C') &&
                            <div>
                                <h4 className="font-weight-bold fs-p">Información de la Transacción</h4>
                                <p>El usuario {data.seller.id}! ({data.seller.full_name}) confirmó el envio del articulo de la operación #{data.id} el {moment(data.date_product_sent).format('DD/MM/YYYY hh:mm:ss')}</p> 
                                <p>Libere el deposito en garantía (saldo) una vez reciba el artículo.</p>
                            </div>
                        }
                        <h4 className="font-weight-bold fs-p">Información del vendedor</h4>
                        <div className="seller-data-content">
                            <div className="row mb-3">
                                <div className="col-md-4 mb-2 col-4">
                                    ID
                                </div>
                                <div className="col-md-8 mb-2 col-8">
                                    {data.seller.id}
                                </div>
                                <div className="col-md-4 mb-2 col-4">
                                    Nombre
                                </div>
                                <div className="col-md-8 mb-2 col-8">
                                    {data.seller.full_name}
                                </div>
                                <div className="col-md-4 mb-2 col-4">
                                    Ubicación
                                </div>
                                <div className="col-md-8 mb-2 col-8">
                                    {data.seller.location}
                                </div>
                                <div className="col-md-4 mb-2 col-4">
                                    WhatsApp/Celular
                                </div>
                                <div className="col-md-8 mb-2 col-8">
                                    {data.seller.phone} <i onClick={(e) => contactByWhatsappNumber(e)} style={{cursor:'pointer'}} className="fa h6 fa-whatsapp text-success ml-2"></i>
                                </div>
                            </div>
                            <h4 className="font-weight-bold fs-p">
                                Información del artículo
                            </h4>
                            <div className="row mb-3">
                                <div className="col-6">
                                    <img src={((data.photos.length > 0) ? data.photos[0].file : '')} className="img-fluid w-100" alt=""/>
                                </div>
                                <div className="col-6">
                                    <p className="mb-1">{data.title}</p>
                                    <p className="font-weight-bold">US$ {Number(data.amount).toFixed(2)}</p>
                                </div>
                            </div>
                            <p className="">Devoluciones: {(data.accept_returns) ? 'El comprador paga por la devolución del artículo' : 'No se aceptan devoluciones'}</p>
                            {(status === 'S') &&
                                <div>
                                    <p className="">Contacta con el vendedor para confirmar la dirección en donde recibirás el artículo</p>
                                    <h4 className="font-weight-bold fs-p">Liberar saldo</h4>
                                    <p className="">El vendedor AÚN NO CONFIRMA el envío del artículo. El vendedor tiene {data.remaining_time_to_send.text} para enviar el artículo antes que esta operación se cancele automáticamente</p>
                                </div>
                            }
                            {(status === 'C' || status === 'S') &&
                                <button onClick={() => (status === 'S') ? console.log('') : setShowModalPay(true)} disabled={sendAmount || status === 'S'} className={((status === 'S') ? 'btn-darkgray' : 'btn-success')+" btn d-block mx-auto text-white btn-sm fs-p font-weight-bold mt-4 mb-4 py-2 px-4"}>
                                    {(!sendAmount) ? 'Liberar Saldo' : <i class="fa fa-spin fa-spinner"></i>}
                                </button>
                            }
                            {(status === 'C' || status === 'S') &&
                                <div>
                                    <h4 className="font-weight-bold fs-p">Iniciar una disputa</h4>
                                    <p className="">Si el vendedor no responde, no realiza el envío del artículo o hay algún descuerdo puede contactar con soporte para iniciar una disputa, haciendo</p>
                                    <a href="##" className="text-primary text-underline">Click aquí</a>
                                </div>
                            }
                        </div>
                    </div>
                    {(status === 'R') &&
                        <div className="w-100 bg-graypp mt-4 p-3">
                            <h4 className="font-weight-bold fs-p">Calificar al vendedor ({data.seller.full_name})</h4>
                            <RatingChoices 
                                defaultValue={(data.rating_for_the_other) ? data.rating_for_the_other : ''}
                                id={data.id} 
                            />
                        </div>
                    }
                </div>
            </div>
            <Modal className="modal-market-pp-two modal-vcenter" dialogClassName="modal-md" aria-labelledby="modal-liberar-saldo" show={showModalPay} onHide={() => setShowModalPay(false)}>
                <Modal.Header className="py-2" closeButton>
                    <Modal.Title as="h3" className="fs-p font-weight-bold mt-1 mb-0">
                        Liberar Saldo
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="mt-2 h6 font-weight-bold">¿Ha recibido el artículo enviado por jorge lopez - id 2WH67?. Al recibir el artículo usted debe "Liberar el saldo" en favor del vendedor.</p>
                    <div className="position-relative content-section-absolute">
                        <div className="section-absolute w-100 bg-yellow py-2 f-red d-flex font-weight-bold f-red align-items-center justify-content-center">
                            <h4 className="mb-0">¡ADVERTENCIA!</h4>
                        </div>
                    </div>
                    <div className="md-checkbox">
                        <input defaultChecked={termOne} type="checkbox" id="term_1" /> 
                        <label htmlFor="term_1" onClick={() => setTermOne(!termOne)} className="h7 font-weight-bold">
                            Acepto que solo puedo liberar el saldo una vez que el artículo este en mi poder.
                        </label>
                    </div>
                    <div className="md-checkbox">
                        <input defaultChecked={termTwo} type="checkbox" id="term_2" /> 
                        <label htmlFor="term_2" onClick={() => setTermTwo(!termTwo)} className="h7 font-weight-bold">
                            Acepto y declaro que no debo liberar saldo con solo recibir una copia del comprobante del envío del artículo.
                        </label>
                    </div>
                    <div className="md-checkbox">
                        <input defaultChecked={termThree} type="checkbox" id="term_3" /> 
                        <label htmlFor="term_3" onClick={() => setTermThree(!termThree)} className="h7 font-weight-bold">
                            Acepto y declaro que al realizar la liberación de saldo sin verificar y confirmar el envío del artículo a mi favor puedo ser victima de fraude.
                        </label>
                    </div>
                </Modal.Body>
                <Modal.Footer className="pt-0 pb-4">
                    <button  onClick={(e) => releaseAmount(e)} disabled={(sendAmount || !termOne || !termTwo || !termThree)} className="btn fs-p font-weight-bold btn-success"> 
                        {(!sendAmount) ? <Fragment><i className="fa fa-check mr-2"></i>Si, liberar el saldo ahora</Fragment> : <i class="fa fa-spin fa-spinner"></i>}
                    </button>
                    <button  onClick={() => setShowModalPay(false)} className="btn fs-p font-weight-bold bg-graypp"><i className="fa fa-times mr-2"></i>Cancelar</button>
                </Modal.Footer>
            </Modal>
            <Rules />
        </div>
    )
}


export default BuyerPurchase;
