import React, {useEffect, useState} from 'react'
import axios from 'axios'

import SellNow from '../sell_now'

function EditAdvertisement() {
    return (
        <div>
            <SellNow edit={true} />
        </div>
    )
}

export default EditAdvertisement;
