import React from 'react'
import {withRouter} from 'react-router-dom';
import {Button} from 'react-bootstrap';

function TabsMobile(props) {

    const toLink = (url) =>{
        if(props.location.pathname !== url){
            props.history.push(url);
        }
    }

    return (
        <div className="d-lg-none d-block">
            <div className="justify-content-between d-flex content-tabs-mobile mb-4">
                <Button onClick={() => toLink('/')} className="font-weight-bold f-sm-sm d-block d-flex w-sm-100 align-items-center" variant="default">
                    <i className="d-none d-md-block fa fa-search mr-1"></i> Explorar
                </Button>
                <Button onClick={() => toLink('/sell-now/')} className="font-weight-bold f-sm-sm d-block d-flex w-sm-100 align-items-center" variant="default">
                    <i className="d-none d-md-block fa fa-camera mr-1"></i> Vender ahora
                </Button>
                <Button onClick={() => toLink('/my-shopping/')} className="font-weight-bold f-sm-sm d-block d-flex w-sm-100 align-items-center" variant="default">
                    <span className="d-none d-md-block iconpp icon-my-sales mr-1"></span> Mis compras
                </Button>
                <Button onClick={() => toLink('/my-sales/')} className="font-weight-bold f-sm-sm d-block d-flex w-sm-100 align-items-center" variant="default">
                    <span className="d-none d-md-block iconpp icon-my-shopping mr-1"></span> Mis ventas
                </Button>
            </div>
        </div>
    )
}

export default withRouter(TabsMobile);
