import React, {useEffect, useState, useContext} from 'react'
import {Redirect, useLocation} from 'react-router-dom';

import {Store} from '../../../storage/Store';
import {handleLogout, setAcessToken} from '../../../storage/Actions';
import FullLoader from '../../helpers/loader/FullLoader';

function Logout() {
    const {state, dispatch} = useContext(Store);
    const [isRedirect, setIsRedirect] = useState(false);

    useEffect(() => {
        handleLogout(dispatch);
        setAcessToken(dispatch, '');
    });

    return (
        <div>
            <FullLoader />
        </div>
    )
}

export default Logout;
