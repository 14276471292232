//modules
import React, {useContext, useEffect , useState, useRef} from 'react';
import {Navbar, NavDropdown, Container} from 'react-bootstrap';
import {Link , NavLink, withRouter, useLocation} from 'react-router-dom';
import NavbarBackoffice from './NavbarBackoffice'; 

//store
import {Store} from '../../storage/Store';
//import {setLanguage, pageLoaded, pageLoading} from '../../storage/Actions';

//routes
import routes  from '../router/routes';

//Transale
import {useTranslate} from 'react-translate';

function usePageViews(pathname, setPathname) {
    let location = useLocation();
    React.useEffect(() => {
        if(pathname !== location.pathname){
            var navbar = document.getElementById('navbar-top');

            if(navbar.classList.contains('show')){
                document.getElementById('burger').click();
            }
        }
    }, [location]);
}

function Header(props) {
    const {state, dispatch} = useContext(Store);
    const [path, setPath] = useState('public');
    const [pathname, setPathname] = useState(props.history.location.pathname);

    let t = useTranslate('header');
    let tmenu = useTranslate("menu");
    
    const translateRoutes = routes(tmenu);
    const navigation = translateRoutes.filter(routeList => routeList.middleware === path)[0];
    const navRoutes = navigation.routes;

    usePageViews(pathname, setPathname);

    useEffect(() => {
        //console.log('cambiandooo');

        if(state.is_loged){
          setPath('auth');
        }else{
          setPath('public');
        }
      },[state.is_loged, dispatch]);

    const changeLanguage = async (newLanguage) => {
        let actualLang = state.lang;
        let url = window.location.href;

        let resultUrl = url.replace('/'+actualLang+'/', '/'+newLanguage+'/');
        window.location.href = resultUrl;
    }

    function changeLanguageTo(e){
        e.preventDefault();
        if(state.lang === 'es'){
            changeLanguage('en');
        }else{
            changeLanguage('es');
        } 
    }
    

    return (
        <header>
            <Navbar className="header-nav navbar-default" id="header-nav-publimarket" fixed="top" expand="lg">
                <Container>
                    <div className="navbar-header page-scroll">
                        <a href="https://publipaid.com/es/" className="page-scroll nav-logo navbar-brand" title="PubliPaid logo"></a>
                        <Navbar.Toggle
                            aria-controls="navbar-top"
                            id="burger"
                            children={
                                <React.Fragment> 
                                    <span className="sr-only">Toggle navigation</span> 
                                    <i className="fa fa-bars"></i> 
                                </React.Fragment>}
                            />
                    </div>

                    <Navbar.Collapse id="navbar-top" className="content-nav-header">
                        <React.Fragment>
                            <ul className="navbar-nav bd-navbar-nav flex-row">
                                {navRoutes && navRoutes.length && navRoutes.map((item, key) => {
                                    var renderItemNav = (item.subitem === null)
                                        ? <li className="nav-item" key={key}>
                                                {(item.target)
                                                ?  
                                                <a target={item.target} href={item.url}>{item.name}</a>
                                                : 
                                                <NavLink activeClassName='active' to={item.url}>
                                                    {item.name}
                                                </NavLink>
                                                }
                                            </li>
                                        : <li className="nav-item" key={key}>
                                            <NavDropdown title={item.name} id={`id-${item.name}-dropdown`}>
                                                {item
                                                    .subitem
                                                    .map((subitem, idx) => {
                                                        console.log(idx);
                                                        return <React.Fragment>
                                                            <Link activeClassName='active' className="dropdown-item" href={subitem.url}>
                                                                {subitem.name}
                                                            </Link>
                                                        </React.Fragment>
                                                    })
}
                                            </NavDropdown>
                                        </li>;
                                    return renderItemNav;
                                })}
                            </ul>
                            {(state.is_loged === true) &&
                                <div className="d-lg-none d-block">
                                    <NavbarBackoffice panelReferrals={false} />
                                </div>
                            }
                            <div className="d-none d-lg-block header-contact">
                                <i className="fa fa-whatsapp mr-2" aria-hidden="true"></i>
                                {t('CONTACT_TEXT')}
                            </div>
                        </React.Fragment>
                    </Navbar.Collapse>
                    <ul className="languages">
                        <li>
                            <form id="frmLangSwitcher">
                                <a  style={{cursor: 'pointer'}}
                                    href="##"
                                    onClick={(e) => changeLanguageTo(e)}>{t("LANGUAGE_TEXT")}</a>
                            </form>
                        </li>
                    </ul>
                    {(state.is_loged) &&
                        <ul className="navbar-msg d-none d-lg-block">
                            <li>
                                <Link to="/usermessages/" className="l-msg">
                                    <span className="icon icon-msg"></span>
                                    <span className="l-msg count-msg">...</span>
                                </Link>
                            </li>
                        </ul>
                    }
                </Container>
            </Navbar>
            <div className="d-lg-none mt-5 pt-4">
                <div className="whatsapp-green-bar">
                    <div className="container">
                        <i className="fa fa-whatsapp mr-2" aria-hidden="true"></i>
                        {t('CONTACT_TEXT')}
                    </div>
                </div>
            </div>
        </header>
    )
}

export default withRouter(Header);