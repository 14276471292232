//modules
import React, {useEffect} from 'react';

export default function Component(props){
    const {body} = props;

    return(
        <div id="content-page">
            {body}
        </div>
    );
}