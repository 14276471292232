import React, {useState, useEffect, useContext} from 'react'
import axios from 'axios';
import {Store} from '../../../storage/Store';
import {setAcessToken, loginUser} from '../../../storage/Actions';
import Loader from '../../helpers/loader';
import FullLoader from '../../helpers/loader/FullLoader';

function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [sending, setSending] = useState(false);
    const {state , dispatch} = useContext(Store);
    const [loading, setLoading] = useState(true);

    const getAccessToken = () => {
        return window.appData.mpt;
    }

    useEffect(() => {
        if(loading){
            setLoading(false);
            if (window.location.origin === 'http://localhost:3000' || window.location.origin === 'http://marketplacepp.pegasohost.net') {
                console.log('login');
            } else {
                window.location.href = "https://publipaid.com/es/iniciar-sesion/";
            }
        }
    });

    const handleLogin = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setSending(true);

        async function loadData() {
            let urlauth = 'https://publipaid.com/api/token/';
            await axios({
                method: 'post',
                url: urlauth,
                data:{
                    email, 
                    password
                }
            }).then(async (res) => {
                let dataAuth = res.data;
                console.log(dataAuth);

                //logeando en el state
                await setAcessToken(dispatch, dataAuth.access);
                await loginUser(dispatch);
                setSending(false);

            }).catch(error => {
                console.log(error);
                alert('Error al intentar loguearse, verifique sus datos e intente de nuevo');
                setSending(false);
            });
        } 

        loadData();
    }

    if(!loading){
        return (
            <div className="py-5 my-3">
                <center>
                    <h3 className="f-bold">Login</h3>
                    <form action="" onSubmit={(e) => handleLogin(e)}>
                        <div className="row">
                            <div className="col-md-5 mx-auto">
                                <div className="form-group">
                                    <input value={email} onChange={(e) => setEmail(e.target.value)} name="email" placeholder="email" className="form-control" type="text"/>
                                </div>
                                <div className="form-group">
                                    <input value={password} onChange={(e) => setPassword(e.target.value)} name="password" placeholder="password" className="form-control" type="password"/>
                                </div>
                                <div className="mt-3">
                                    <button type="submit" disabled={sending} className="btn btn-block w-100 btn-success">
                                        {sending ? <i className="fa fa-spin fa-spinner"></i> : 'Ingresar'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </center>
            </div>
        )
    }else{
        return (
            <FullLoader />
        )
    }
}

export default Login;
