import _header from './es/header.json';
import _registro from './es/registro.json';
import _login from './es/login.json';
import _transferir from './es/transferir.json';
import _recuperar_clave from './es/recuperar_clave.json';

import _cambiar_contrasena from './es/cambiar_contrasena.json';
import _eliminar_cuenta from './es/eliminar_cuenta.json';
import _mi_testimonio from './es/mi_testimonio.json';
import _verificar_cuenta from './es/verificar_cuenta.json';
import _tabs_cuenta from './es/tabs_cuenta.json';
import _actualizar_cuenta from './es/actualizar_cuenta.json';

import _menu from './es/menu.json';
import _sidebar from './es/sidebar.json';
import _reset_password_done from './es/reset_password_done.json';
import _set_password from './es/set_password.json';
import _confirmar_email_enviado from './es/confirmar_email_enviado.json';
import _set_password_fail from './es/set_password_fail.json';
import _reset_done from './es/reset_done.json'; 
import _not_found from './es/not_found.json';
import _preguntas_frecuentes from './es/preguntas_frecuentes.json';
import _file_input from './es/file_input.json';
import _ws_groups from './es/ws_groups.json';
import _rented_referrals from './es/rented_referrals.json';
import _manage_operations from './es/manage_operations.json';
import _tab_market from './es/tab_market.json';
import _manage_operation_sales from './es/manage_operation_sales.json';
import _manage_operation_deposit from './es/manage_operation_deposit.json';
import _withdraw from './es/withdraw.json';
import _deposit from './es/deposit.json';
import _direct_referrals from './es/direct_referrals.json';
import _rented_referrals_referrals from './es/rented_referrals_referrals.json';
import _anti_spam_policy from './es/anti_spam_policy.json';
import _privacy_policy from './es/privacy_policy.json';
import _policy_of_refund from './es/policy_of_refund.json';
import _terms from './es/terms.json';
import _advertisements from './es/advertisements.json';
import _show_ads from './es/show_ads.json';
import _movements from './es/movements.json';
import _ranks from './es/profile/ranks.json';

//market
import _market_ad from './es/market/market_ad.json';

//modals
import _modal_warning from './es/modals/modal_warning.json';

export default {
    locale: "es",
    header: _header,
    register:_registro,
    login: _login,
    reset_password: _recuperar_clave,
    reset_password_done:_reset_password_done,
    set_password: _set_password,
    set_password_fail:_set_password_fail,
    reset_done: _reset_done,
    not_found: _not_found,
    confirmation_email_sent:_confirmar_email_enviado,
    menu:_menu,
    sidebar:_sidebar,
    transfer: _transferir,
    password_change: _cambiar_contrasena,
    tabs_account: _tabs_cuenta,
    account_close: _eliminar_cuenta,
    my_testimony: _mi_testimonio,
    user_verification: _verificar_cuenta,
    update_account: _actualizar_cuenta,
    faq: _preguntas_frecuentes,
    fileInput: _file_input,
    ws_groups: _ws_groups,
    rented_referrals: _rented_referrals,
    manage_operations: _manage_operations,
    tab_market: _tab_market,
    manage_operation_sales: _manage_operation_sales,
    manage_operation_deposit: _manage_operation_deposit,
    withdraw: _withdraw,
    deposit: _deposit,
    direct_referrals: _direct_referrals,
    rented_referrals_referrals: _rented_referrals_referrals,
    anti_spam_policy: _anti_spam_policy,
    privacy_policy: _privacy_policy,
    policy_of_refund: _policy_of_refund,
    terms: _terms,
    advertisements: _advertisements,
    show_ads: _show_ads,
    movements: _movements,
    market_ad: _market_ad,
    modal_warning: _modal_warning,
    ranks: _ranks
}
