const Routes = (translate) => {
    return [
        {
            middleware: 'public',
            routes: [
                {
                    name: translate("TESTIMONIALS"),
                    url: 'https://publipaid.com/es/testimonials/',
                    icon: null,
                    subitem: null,
                    target: "_self"
                },
                {
                    name: translate("ABOUT_US"),
                    url: 'https://publipaid.com/es/nosotros/',
                    icon: null,
                    subitem: null,
                    target: "_self"
                },
                {
                    name: translate("HELP"),
                    url: 'https://publipaid.com/es/preguntas-frecuentes/',
                    icon: null,
                    subitem: null,
                    target: "_self"
                },
                {
                    name: translate("CONTACT_US"),
                    url: 'https://publipaid.com/es/contactenos/',
                    icon: null,
                    subitem: null,
                    target: "_self"
                },
                {
                    name: translate("SIGN_UP"),
                    url: 'https://publipaid.com/es/registro/',
                    icon: null,
                    subitem: null,
                    target: "_self"
                },
                {
                    name: translate("LOGIN"),
                    url: '/login',
                    icon: null,
                    subitem: null
                }
            ]
        },
        {
            middleware: 'auth',
            routes: [
                {
                    name: translate("TESTIMONIALS"),
                    url: 'https://publipaid.com/es/testimonials/',
                    icon: null,
                    subitem: null,
                    target: "_self"
                },
                {
                    name: translate("ABOUT_US"),
                    url: 'https://publipaid.com/es/nosotros/',
                    icon: null,
                    subitem: null,
                    target: "_self"
                },
                {
                    name: translate("HELP"),
                    url: 'https://publipaid.com/es/preguntas-frecuentes/',
                    icon: null,
                    subitem: null,
                    target: "_self"
                },
                {
                    name: translate("CONTACT_US"),
                    url: 'https://publipaid.com/es/contactenos/',
                    icon: null,
                    subitem: null,
                    target: "_self"
                },
                {
                    name: translate("MY_ACCOUNT"),
                    url: 'https://publipaid.com/es/mi-cuenta/',
                    icon: null,
                    subitem: null,
                    target: "_self"
                },
                {
                    name: translate("LOGOUT"),
                    url: 'https://publipaid.com/es/cerrar-sesion/',
                    icon: null,
                    subitem: null,
                    target: "_self"
                }
            ]
        }
    ]
    
    };
    
    export default Routes;