import React, {useState, Fragment, useContext} from 'react'

import {Link, Route, withRouter} from 'react-router-dom'
import {Store} from '../../../../storage/Store';
import SearchBar from '../../../helpers/searchbar';
import TabsMobile from '../../../helpers/tabsMobile';

function SidebarLink({ actualPath = '', subMenu = false, subMenuItems = [], matchUrl, activeWithPathnames = [], label, to, icon, activeOnlyWhenExact }) {

    const [categoryActive, setCategoryActive] = useState('');

    //console.log(actualPath);
    //console.log(matchUrl);
    //console.log(activeWithPathnames.includes(actualPath));
    
    return (
      <Route
        path={to}
        exact={activeOnlyWhenExact}
        children={({ match}) => (
            <Fragment>
                {(!subMenu)?
                    <li className={(match || (!activeOnlyWhenExact && activeWithPathnames.includes(actualPath))) ? "active" : ""}>
                        <Link to={{ pathname: to }} className="page-scroll">
                            {icon}
                            {label}
                        </Link>
                    </li> 
                :
                    <li className={(match || (!activeOnlyWhenExact && activeWithPathnames.includes(actualPath))) ? "active pp-item-menu-content" : "pp-item-menu-content"}>
                        <Link to={to} onClick={() => ((categoryActive === '') ? setCategoryActive(to) : setCategoryActive(''))} 
                        className={(categoryActive === to) ? 'active page-scroll' : 'page-scroll'}>
                            {icon}
                            {label}
                        </Link>
                            <ul className="pp-submenu">
                                {/*<Link to={{ pathname: to }} className="page-scroll pp-item-menu">
                                        <i className="fa fa-eye"></i>
                                        Ver todo
                                </Link>*/}
                                {subMenuItems.map((subitem, index) => {
                                    return <li key={index} className={(matchUrl.subcategory && subitem.slug.includes(matchUrl.subcategory)) ? "active" : ""}>
                                                <Link to={{ pathname: to+'/'+subitem.slug }} className="page-scroll">
                                                    <i className="fa fa-arrow-right mr-2"></i>
                                                    {subitem.title}
                                                </Link>
                                            </li>
                                })}
                            </ul> 
                    </li>
                }
            </Fragment>
        )}
      />
    );
}

function ModalCategories(props) {

    const {state} = useContext(Store);
    let categories = state.navbarCategories;

    const [showModalCategories, setshowModalCategories] = useState(false);
    const [topOffset] = useState(100);

    const handleshowModalCategories = async () => {
        //let navbarheight = document.querySelector('#header-nav-publimarket');
        //let heghtNavbar = window.getComputedStyle(navbarheight).height;

        //await setTopOffset(heghtNavbar);
        setshowModalCategories(true);
    }
    
    return (
        <div>
            {(showModalCategories) &&
                <div 
                    //style={{paddingTop: topOffset}} 
                    className="content-modal-categories pt-4 pb-4 d-lg-none d-block"
                >
                    <div className="container">
                        <div className="menu-backoffice">
                            <div className="navbar-backoffice-market">
                                <ul className="nav navbar-nav navbar-referrels">
                                    <li className="separator market mb-2 d-flex justify-content-between align-items-center font-weight-bold">
                                        <span>Categorias</span>
                                        <button onClick={() => setshowModalCategories(false)} className="btn btn-sm btn-link mr-2">
                                            <i className="fa fa-times mr-1"></i>Cancelar
                                        </button>
                                    </li>
                                    {categories && categories.length && categories.map((item, key) => {
                                        var linkCategory = <SidebarLink 
                                                                key={key}
                                                                to={'/'+item.slug} 
                                                                icon={<span className={item.icon_css_class}></span>} 
                                                                label={item.title} 
                                                                activeOnlyWhenExact={true} 
                                                                matchUrl={props.match.params} 
                                                                actualPath={props.location.pathname} 
                                                                subMenu={true} 
                                                                subMenuItems={item.subcategories}
                                                            />

                                        return linkCategory;
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <h6 style={{cursor: 'pointer'}} onClick={() => handleshowModalCategories()} className="d-lg-none d-block mt-4 font-weight-bold text-primary">
                Categorias <i className="fa fa-angle-down"></i>
            </h6>

            <h6 className="d-lg-block d-none mt-4 font-weight-bold text-primary">
                Categorias 
            </h6>
        </div>
    )
}

export default withRouter(ModalCategories);
