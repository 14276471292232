import React, {useState, useEffect} from 'react'
import image from '../../../../assets/market/see-ad/image.png';

import Rules from '../../../helpers/buy-sell-rules';
import {OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';

import RatingChoices from '../../../helpers/ratingChoices';

import Chat from '../../../helpers/chat';

function SellerPurchase(props) {

    const [status, setStatus] = useState(props.status);
    const [data, setData] = useState(props.data);

    useEffect(() => {
        console.log(props.data); 
    });

    const [showModalConfirm, setShowModalConfirm] = useState(false);
    const [showModalPay, setShowModalPay] = useState(false);
    const [sendPayment, setSendPayment] = useState(false);
    const [showModalCancel, setShowModalCancel] = useState(false);

    const [cancelling, setCancelling] = useState(false);

    const paymentConfirm = (e) => {
        e.preventDefault();
        setSendPayment(true);
        
        let url = '/es/ecommerce/api/advertisements/'+data.id+'/send-product/';
        axios({
            url, 
            method: 'put'
        }).then((res) => {
            console.log(res);
            props.resetComponent();
        }).catch((err) => {
            console.error(err);
            setSendPayment(false);
        });
        
    }

    const cancelOperation = () => {
        setCancelling(true);
        let url = '/es/ecommerce/api/advertisements/'+data.id+'/cancel/';

        axios({
            url,
            method: 'put'
        }).then((res) => {
            console.log(res);
            setShowModalCancel(false);
            props.resetComponent();
            setCancelling(false);
        }).catch((err) => {
            console.error(err);
            setCancelling(false);
        });
    }

    const contactByWhatsappNumber = (e) => {
        e.preventDefault();
        let phoneWhitoutSimbol = data.phone.replace('+', '');
        //let url = 'https://web.whatsapp.com/send?phone='+584144406463;
        //https://faq.whatsapp.com/general/26000030/?lang=es
        let url = 'https://wa.me/'+phoneWhitoutSimbol;
        window.open(url, '_blank');
    }

    return (
        <div>
            <div className="d-none mb-4">
                <button onClick={() => setStatus('R')}>Liberado</button>
                <button onClick={() => setStatus('C')}>Enviado</button>
                <button onClick={() => setStatus('S')}>Confirmar</button>
                <button onClick={() => setStatus('T')}>Cancelar</button>
                <button onClick={() => setStatus('cad')}>Caducado</button>
            </div>
            <p className="mb-4">
                <a href="##" className="text-primary mr-2">Operación #{data.id}:</a> {data.title} - US$ {Number(data.amount).toFixed(2)}
            </p>

            <p className="mb-4 bg-graypp py-3 px-3"><span className="font-weight-bold mr-2">Estado de la transacción:</span>{data.status_name}</p>
            
            <div className="row">
                <div className="col-md-6">
                    <Chat 
                        id={data.id}
                        messages={(data.chat_messages ? data.chat_messages : [])}
                        otherUser={data.buyer}
                    /> </div>
                <div className="col-md-6">
                    <div className="w-100 bg-graypp p-3">
                    

                        {(status === 'C') &&
                            <div>
                                <h4 className="font-weight-bold fs-p">Artículo enviado, esperando liberación del deposito en garantía</h4>
                                <p>Usted ha confirmado el envío del artículo el {moment(data.date_product_sent).format('DD/MM/YYYY hh:mm:ss')} El comprador {data.buyer.id}! ({data.buyer.full_name}) liberará el saldo una vez recibido el artículo.</p>
                                <p>Para algilizar el proceso de liberacion de fondos, por favor haga el seguimiento al artículo enviado y mantengase en contacto con el comprador.</p>
                            </div>
                        }

                        {(status === 'S') &&
                            <div>
                                <h4 className="font-weight-bold fs-p"><span className="text-danger mr-2">Paso 1:</span> Enviar el artículo</h4>
                                <p>Contacta con el comprador y solicita una dirección para enviar el artículo</p>
                            </div>
                        }

                        {(status === 'T' || status === 'D') &&
                            <div>
                                <h4 className="font-weight-bold fs-p">Información de la Transacción</h4>
                                <p>Esta operación comercial se cancelo el {moment(data.date_cancelled).format('DD/MM/YYYY hh:mm:ss')} y fue cancelada por el vendedor, todavia puede enviar mensajes al <span className="text-primary">comprador.</span></p>
                            </div>
                        }

                        {(status === 'R') &&
                            <div>
                                <h4 className="font-weight-bold fs-p">Información de la Transacción</h4>
                                <h4 className="font-weight-bold fs-p">Operación completada</h4>
                                <p>Esta operacion comercial se cerro el {moment(data.date_amount_released).format('DD/MM/YYYY hh:mm:ss')}</p>
                                <p>Todavia puede enviar mensajes al comprador</p>
                            </div>
                        }

                        {(status === 'I' || status === 'X') &&
                            <div>
                                <h4 className="font-weight-bold fs-p">Información de la Transacción</h4>
                                <p>Esta operación comercial caduco, de manera automatica, por falta de confirmacion del envío del artículo por parte del vendedor. </p>
                                <p>Esto ocurre cuando el vendedor no cumple con enviar el artículo al comprador en plazo definido en la transacción.</p>
                            </div>
                        }

                        <h4 className="font-weight-bold fs-p">Información del comprador</h4>
                        <div className="seller-data-content">
                            <div className="row mb-3">
                                <div className="col-md-4 mb-2 col-3">
                                    ID:
                                </div>
                                <div className="col-md-8 mb-2 col-9">
                                    {data.buyer.id}
                                </div>
                                <div className="col-md-4 mb-2 col-3">
                                    Nombre:
                                </div>
                                <div className="col-md-8 mb-2 col-9">
                                    {data.buyer.full_name}
                                </div>
                                <div className="col-md-4 mb-2 col-3">
                                    Ubicación:
                                </div>
                                <div className="col-md-8 mb-2 col-9">
                                    {data.buyer.location}
                                </div>
                                <div className="col-md-4 mb-2 col-3">
                                    WhatsApp:
                                </div>
                                <div className="col-md-8 mb-2 col-9">
                                    {data.buyer.phone} <i onClick={(e) => contactByWhatsappNumber(e)} style={{cursor:'pointer'}} className="fa h6 fa-whatsapp text-success ml-2"></i>
                                </div>
                            </div>
                            <h4 className="font-weight-bold fs-p">Información del artículo</h4>
                            <div className="row mb-3">
                                <div className="col-6">
                                    <img src={((data.photos.length > 0) ? data.photos[0].file : '')} className="img-fluid w-100" alt=""/>
                                </div>
                                <div className="col-6">
                                    <p className="mb-1">{data.title}</p>
                                    <p className="font-weight-bold">US$ {Number(data.amount).toFixed(2)}</p>
                                </div>
                            </div>
                            <p className="">Devoluciones: {(data.accept_returns) ? 'El comprador paga por la devolución del artículo' : 'No se aceptan devoluciones'}</p>
                            {(status === 'S') &&
                                <div>
                                    <h4 className="font-weight-bold fs-p"><span className="text-danger mr-2">Paso 2:</span> Confirmar envío del artículo</h4>
                                    <p>Publipaid retiene el monto total de la operación en depósito de garantía durante {data.time_to_send_name} durante los cuales usted puede realizar el envío del artículo de manera segura. Después de enviar el artículo, usted debe adjuntar en la ventana del chat un comprobante o prueba de envío. No olvide incluir en el comprobante la operación #19025, luego haga clic en le botón “CONFIRMAR ENVÍO”, de lo contrario la operación se cancelará automaticamente.</p>
                                    <button onClick={() => setShowModalConfirm(true)} className="btn d-block w-sm-100 mx-auto btn-success btn-sm fs-p font-weight-bold mt-4 mb-4 py-2 px-4">
                                        Confirmar envío
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                    {(status === 'P' || status === 'S' || status === 'C') &&
                            <div className="w-100 bg-graypp mt-4 p-3">
                                    <h4 className="font-weight-bold fs-p">Cancelar Operación</h4>
                                    <p className="mb-0">¿Tienes dudas o prefieres vender tu artículo en otro momento?</p>
                                    <button onClick={() => setShowModalCancel(true)} className="btn d-block w-sm-100 mx-auto btn-danger btn-sm fs-p font-weight-bold mt-3 mb-4 py-2 px-4">
                                        Cancelar Operación
                                    </button>
                            </div>
                    }
                    {(status === 'R') &&
                            <div className="w-100 bg-graypp mt-4 p-3">
                                <h4 className="font-weight-bold fs-p">Calificar al comprador ({data.buyer.full_name})</h4>
                                <RatingChoices 
                                    defaultValue={(data.rating_for_the_other) ? data.rating_for_the_other : ''}
                                    id={data.id} 
                                />
                        </div>
                    }
                    
                </div>
            </div>
            <Rules />
            <Modal className="modal-market-pp-two modal-vcenter" show={showModalConfirm} onHide={() => setShowModalConfirm(false)}>
                <Modal.Header className="py-2" closeButton>
                    <Modal.Title as="h3" className="fs-p font-weight-bold mt-1 mb-0">Confirmar envío del artículo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="mt-2">Yo {data.seller.full_name}, confirmo el envío del articulo y declaro bajo juramento <span className="text-primary text-underline">aceptar los términos legales</span> de esta operación y haber enviado el artículo segun las instrucciones del comprador.</p>
                    <p className="mt-2">Si no estas seguro de como realizar el envío del artículo debes contactar a {data.buyer.full_name} ID: {data.buyer.id} para recibir instrucciones de como enviar el artículo.</p>
                </Modal.Body>
                <Modal.Footer className="pt-0 pb-4">
                    <button disabled={sendPayment} onClick={(e) => paymentConfirm(e)} className="btn px-4 fs-p font-weight-bold btn-success">
                            {(!sendPayment) ? 'Confirmar envío' : <i class="fa fa-spin fa-spinner"></i>}
                    </button>
                    <button onClick={() => setShowModalConfirm(false)} className="btn px-4 fs-p font-weight-bold bg-graypp">
                        No he enviado el artículo
                    </button>
                </Modal.Footer>
            </Modal>


            <Modal className="modal-market-pp-two modal-vcenter" show={showModalCancel} onHide={() => setShowModalCancel(false)}>
                <Modal.Header className="py-2" closeButton>
                    <Modal.Title as="h3" className="fs-p font-weight-bold mt-1 mb-0">Confirmar cancelación de venta de artículo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="mt-2">Está seguro que desea cancelar la operación #{data.id} por un monto de US$ {Number(data.amount).toFixed(2)}?</p>
                </Modal.Body>
                <Modal.Footer className="pt-0 pb-4">
                    <button disabled={cancelling} onClick={() => cancelOperation()} className="btn px-4 fs-p font-weight-bold btn-success">{(cancelling) ? <i className="fa fa-spin fa-spinner"></i> : 'Si, Confirmar'} <i className="fa fa-check ml-2"></i></button>
                    <button onClick={() => setShowModalCancel(false)} className="btn px-4 fs-p font-weight-bold bg-graypp">Cancelar <i className="fa fa-times ml-2"></i></button>
                </Modal.Footer>
            </Modal>

            <Modal className="modal-market-pp-two modal-vcenter" dialogClassName="modal-md" aria-labelledby="modal-liberar-saldo" show={showModalPay} onHide={() => setShowModalPay(false)}>
                <Modal.Header className="py-2" closeButton>
                    <Modal.Title as="h3" className="fs-p font-weight-bold mt-1 mb-0">
                        Liberar Saldo
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="mt-2 h6 font-weight-bold">¿Ha recibido el artículo enviado por jorge lopez - id 2WH67?. Al recibir el artículo usted debe "Liberar el saldo" en favor del vendedor.</p>
                    <div className="position-relative content-section-absolute">
                        <div className="section-absolute w-100 bg-yellow py-2 f-red d-flex font-weight-bold f-red align-items-center justify-content-center">
                            <h4 className="mb-0">¡ADVERTENCIA!</h4>
                        </div>
                    </div>
                    <div className="md-checkbox">
                        <input type="checkbox" id="term_1" /> 
                        <label htmlFor="term_1" className="h7 font-weight-bold">
                            Acepto que solo puedo liberar el saldo una vez que el artículo este en mi poder.
                        </label>
                    </div>
                    <div className="md-checkbox">
                        <input type="checkbox" id="term_2" /> 
                        <label htmlFor="term_2" className="h7 font-weight-bold">
                            Acepto y declaro que no debo liberar saldo con solo recibir una copia del comprobante del envío del artículo.
                        </label>
                    </div>
                    <div className="md-checkbox">
                        <input type="checkbox" id="term_3" /> 
                        <label htmlFor="term_3" className="h7 font-weight-bold">
                            Acepto y declaro que al realizar la liberación de saldo sin verificar y confirmar el envío del artículo a mi favor puedo ser victima de fraude.
                        </label>
                    </div>
                </Modal.Body>
                <Modal.Footer className="pt-0 pb-4">
                    <button className="btn fs-p font-weight-bold btn-success"> <i className="fa fa-check mr-2"></i>Si, liberar el saldo ahora</button>
                    <button onClick={() => setShowModalPay(false)} className="btn fs-p font-weight-bold bg-graypp"><i className="fa fa-times mr-2"></i>Cancelar</button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}


export default SellerPurchase;
