import React, {useState, useEffect, Fragment} from 'react'
import {withRouter, Link , Redirect} from 'react-router-dom';
import {Modal, Alert} from 'react-bootstrap';
import axios from 'axios';

import image from '../../../../assets/market/see-ad/image.png';
import LoaderInline from '../../../helpers/loader/LoaderInline';

function SeeAdSeller(props) {

    const [id, setId] = useState(props.match.params.id);

    const [status, setStatus] = useState('p');
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalCancel, setShowModalCancel] = useState(false);

    const [renewing, setRenewing] = useState(false);

    const [deleting, setDeleting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        if(loading){
            let url = '/es/ecommerce/api/advertisements/'+id+'/';
            if(search){
                setSearch(false);
                console.log('load my advertisement');

                axios({
                    url,
                    method: 'get'
                }).then((res) => {
                    console.log(res);
                    setData(res.data);
                    setLoading(false);
                }).catch((err) => {
                    console.error(err);
                });
            }
        }
    });
    
    /*const handleCloseModalDelete = () => {
        setShowModalDelete(false);
    }*/

    const renewAdvertisement = () => {
        let url = '/es/ecommerce/api/advertisements/'+id+'/renew/';
        setRenewing(true);

        axios({
            url,
            method: 'put'
        }).then((res) => {
            console.log(res);
            setRenewing(false);
            setLoading(true);
            setSearch(true);
        }).catch((err) => {
            console.error(err);
            setRenewing(false);
        });
    }

    const deleteAd = () => {
        let url = '/es/ecommerce/api/advertisements/'+id+'/delete/';
        setDeleting(true);

        axios({
            url,
            method: 'delete'
        }).then((res) => {
            console.log(res);
            setShowModalDelete(false);
            setDeleting(false);
            setLoading(true);
            setSearch(true);
        }).catch((err) => {
            console.error(err);
            setDeleting(false);
        });
    }

    const edit = () => {
        props.history.push('/my-advertisement/'+id+'/edit/');
    }

    var statusMessage = '';

    if(status === 'd'){
        statusMessage = 'Anuncio eliminado';
    }else if(status === 'p'){
        statusMessage = 'Pendiente de aprobación';
    }else if(status === 'pu'){
        statusMessage = 'Publicado';
    }else if(status === 'r'){
        statusMessage = 'Rechazado';
    }else if(status === 'e'){
        statusMessage = 'Expirado';
    }

    return (
        <div>
            {(!loading) &&
            <Fragment>
                {(data.status === 'L') && 
                   <Redirect
                        to={{
                            pathname: "/my-sales/",
                            state: {
                                message: 'Anuncio: '+data.title+' ha sido eliminado'
                            }
                        }}
                    />   
                }
                {(data.status !== 'L') &&
                    <Fragment>
                    {(data.current_user_info) && 
                        <Fragment>
                            {(!data.current_user_info.permissions.can_edit && !data.current_user_info.permissions.can_delete && !data.current_user_info.permissions.can_renew) &&
                                <Redirect
                                    to={{
                                        pathname: "/advertisement/"+id
                                    }}
                                />
                            }
                        </Fragment>
                    }

                    {(props.location.state && props.location.state.message) &&
                        <Alert variant='success'>
                            {props.location.state.message}
                        </Alert>
                    }

                    <p className="mb-4">
                        <a href="##" className="text-primary mr-2">Operación #{data.id}:</a> {data.title} - US$ {Number(data.amount).toFixed(2)}
                    </p>

                    <p className="mb-4 bg-graypp py-3 px-3"><span className="font-weight-bold mr-2">Estado de la transacción:</span>{data.status_name}</p>
                
                    {(status !== 'd') &&
                        <div className="bg-graypp p-3">
                            <h3 className="font-weight-bold fs-p mb-3">Administrar Anuncio</h3>
                            <div className="row mb-3">
                                <div className="col-md-4">
                                    <img src={((data.photos.length > 0) ? data.photos[0].file : '')} className="img-fluid w-100" alt="ad-img"/>
                                </div>
                                <div className="col-md-8">
                                    <p className="mb-1">{data.title}</p>
                                    <p className="mb-4 font-weight-bold">US$ {Number(data.amount).toFixed(2)}</p>
                                    <div className="d-flex flex-wrap align-items-start">
                                        <Link to={"/advertisement/"+data.id} className="btn btn-small h7 px-0 mr-1 font-weight-bold"><i className="mr-1 fa fa-eye"></i>Ver anuncio</Link>
                                        <button disabled={!data.current_user_info.permissions.can_edit}                 onClick={() => edit()}            className="btn btn-small h7 px-0 mr-1 font-weight-bold"><i className="mr-1 fa fa-pencil"></i>Editar anuncio</button>
                                        <button disabled={!data.current_user_info.permissions.can_delete}               onClick={() => setShowModalDelete(true)} className="btn btn-small h7 px-0 mr-1 font-weight-bold"><i className="mr-1 fa fa-trash"></i>Eliminar anuncio</button>
                                        <button disabled={!data.current_user_info.permissions.can_renew || renewing}    onClick={() => renewAdvertisement()} className="btn btn-small h7 px-0 mr-1 font-weight-bold"><i className="mr-1 fa fa-refresh"></i> Renovar anuncio (15 días) {(renewing) ? <i className="fa fa-spin fa-spinner"></i> : ''}</button>
                                    </div>
                                </div>
                            </div>
                            <p className="mb-0 font-weight-bold">Promociona tu artículo para llegar a más compradores potenciales</p>
                            <p className="mb-3 font-weight-bold text-danger">500 clics por solo US$ 5.00</p>
                            <button style={{maxWidth: 320}} className="w-100 my-0 py-2 d-block mx-0 btn btn-lg px-5 font-weight-bold btn-login btn-success mb-3">Promocionar artículo</button>
                        </div>
                    }
                    <div className="mt-3 d-none">
                        <button className="btn btn-sm h7 px-3" onClick={() => setStatus('pu')}>Publicado</button>
                        <button className="btn btn-sm h7 px-3" onClick={() => setShowModalCancel(true)}>Cancelar</button>
                        <button className="btn btn-sm h7 px-3 ml-2" onClick={() => setStatus('r')}>Rechazado</button>
                        <button className="btn btn-sm h7 px-3 ml-2" onClick={() => setStatus('e')}>Expirado</button>
                        <button className="btn btn-sm h7 px-3 ml-2" onClick={() => setStatus('p')}>Pendiente de aprobacion</button>
                    </div>
                    <Modal className="modal-market-pp-two modal-vcenter" show={showModalDelete} onHide={() => setShowModalDelete(false)}>
                        <Modal.Header className="py-2" closeButton>
                            <Modal.Title as="h3" className="fs-p font-weight-bold mt-1 mb-0">Eliminar anuncio</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>¿Seguro que quieres eliminar este anuncio? Esta acción no se puede deshacer.</p>
                        </Modal.Body>
                        <Modal.Footer className="pt-2 pb-4">
                        <button onClick={() => setShowModalDelete(false)} className="btn px-4 fs-p font-weight-bold bg-graypp">Cancelar</button>
                        <button disabled={deleting} onClick={() => deleteAd()} className="btn px-4 fs-p font-weight-bold btn-success">
                            {(deleting) ? <i className="fa fa-spin fa-spinner"></i> : 'Eliminar'}
                        </button>
                        </Modal.Footer>
                    </Modal>

                    <Modal className="modal-market-pp-two modal-vcenter" show={showModalCancel} onHide={() => setShowModalCancel(false)}>
                    <Modal.Header className="py-2" closeButton>
                        <Modal.Title as="h3" className="fs-p font-weight-bold mt-1 mb-0">Confirmar cancelación de venta de artículo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="mt-2">Está seguro que desea cancelar la operación #85178 por un monto de US$ 40.00?</p>
                    </Modal.Body>
                    <Modal.Footer className="pt-0 pb-4">
                        <button className="btn px-4 fs-p font-weight-bold btn-success">Si, Confirmar <i className="fa fa-check ml-2"></i></button>
                        <button onClick={() => setShowModalCancel(false)} className="btn px-4 fs-p font-weight-bold bg-graypp">Cancelar <i className="fa fa-times ml-2"></i></button>
                    </Modal.Footer>
                </Modal>
                    </Fragment>
                }
            </Fragment>
            }

            {(loading) &&
                <LoaderInline />
            }
        </div>
    )
}

export default withRouter(SeeAdSeller);
