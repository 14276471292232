//modules
import React, {useContext, useState, useEffect} from 'react';
import {BrowserRouter} from 'react-router-dom';
import axios from 'axios';

//storage
import {Store} from '../../storage/Store';
import {setCategoriesList, setAcessToken, set_user_data, handleLogout} from '../../storage/Actions';

//socket.io
import io from 'socket.io-client';

import Loader from '../helpers/loader';

export default function Router(props){
    
    const {state, dispatch} = useContext(Store);
    const [actualLang, setActualLang] = useState(state.lang);

    const [actualStateLogin, setActualStateLogin] = useState(state.is_loged);

    const [loading, setLoading] = useState(true);
    const [loadDataHelper, setLoadDataHelper] = useState(true);

    const [count, setCount] = useState(0);

    const [searchDataUser, setSearchDataUser] = useState(true);
    const [loadingDataUser, setLoadingDataUser] = useState(true);

    const [loadingRoutes, setLoadingRoutes] = useState(false);
    const [auth, setAuth] = useState(false);

    const [initialLoged, setInitialLoged] = useState(false);
    
    let basename = (state.is_loged ? actualLang+'/marketplace/' : actualLang);
    let failRequestByToken = false;

    const getAccessToken = () => {
        return window.appData.mpt;
    }

    useEffect(() => {
        //console.log('verificandoooooooooooo');
        //console.log(basename);

        let categories = state.navbarCategories;
        let numberCategories = categories.length;

        axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if (!error.response) {
                alert('NETWORK ERROR, revise su conexion a internet')
            } else {
                const code = error.response.status
                const response = error.response.data
                const originalRequest = error.config;
        
                if (code === 401 && !originalRequest._retry && !failRequestByToken) {
                    originalRequest._retry = true;
                    failRequestByToken = true;
                    console.log('El token ha caducado, logout');
                    handleLogout(dispatch);
                    setAcessToken(dispatch, '');
                    failRequestByToken = false;
                }
            }
        })

        async function loadData() {
            
            let urlauth = 'https://publipaid.com/api/token/';

            //Loguin hardcoreado antiguo
            /*
            let vendedor = {email: "frontenddev1@pegasohost.net", password: "Halter789"}
            let comprador = {email: "userdummy@uber-mail.com", password: "ud1234567"}
            await axios({
                method: 'post',
                url: urlauth,
                data:comprador
            }).then(async (res) => {
                let dataAuth = res.data;
                console.log(dataAuth);
                await setAcessToken(dispatch, dataAuth.access);
                setAuth(true);
            }).catch(error => {
                console.log(error);
            });*/
            
            await setAcessToken(dispatch, getAccessToken());
            setAuth(true);
        } 

        async function loadCategories(){
            let url = '/es/ecommerce/api/categories/';
            if(numberCategories === 0){
                await axios.get(url,{crossDomain: true})
                .then(async (res) => {
                    console.log(res);
                    await setCategoriesList(dispatch, res.data);
                    //Indica que las categorias ya fueron cargadas y renderiza la app
                    //console.log('Todo cargado');
                }).catch(error => {
                    console.log(error)
                });
            }
        }  

        if(loading === true){
            if(state.lang !== actualLang){
                //actualiza el lenguaje actual al lenguaje activo en el store
                setActualLang(state.lang);
            }else if(auth === false && loadDataHelper === true){
                //servicio de autenticacion
                loadData(); 
                setLoadDataHelper(false);
            }else{
                if(state.is_loged){
                    console.log('logueado');
                    setLoading(false);
                }else{
                    console.log('No logueado');
                    setLoading(false);
                }
            }
        }else{
            console.log('renderizando');
            if(initialLoged !== state.is_loged){
                setCount(count+1);
                setInitialLoged(state.is_loged);
                setLoading(true);
            }
        }
    }, [state.lang, actualLang, dispatch, auth, state.accessTkn, state.navbarCategories, loading]);

    //refrescamos el children para que actualice el lenguaje al que esta activo en el store

    const renderBrowserRouter = (loading 
    ?
        <Loader />
    : 
        <BrowserRouter basename={basename}>
            {props.children}
        </BrowserRouter>
    );

    return renderBrowserRouter;
}